.bodyView {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.procedureContainer {
  height: 800px;
  width: 100%;
  position: relative;
  top: -73px;
  background-color: #E6E6E6;
}
.procedureContainer h2 {
  font-size: 7rem;
  text-transform: uppercase;
  font-family: "Manrope-SemiBold", sans-serif;
  color: rgba(0, 0, 0, 0.05);
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.procedureContainer .procedureContainer-content__elements {
  display: flex;
  justify-content: space-between;
  height: 80%;
  margin-top: 10em;
}
.procedureContainer .procedureContainer-content__elements .fade-in-out {
  animation-name: fade-in-out;
  animation-duration: 0.7s;
}
@keyframes fade-in-out {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.procedureContainer .procedureContainer-content__elements .procedureContainer-content__text {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  width: 30%;
  margin-left: 12.5%;
  transition: 0.5s;
}
.procedureContainer .procedureContainer-content__elements .procedureContainer-content__text .title {
  font-size: 4rem;
  font-family: "Montserrat-SemiBold", sans-serif;
}
.procedureContainer .procedureContainer-content__elements .procedureContainer-content__text .text {
  font-size: 2rem;
  font-family: "Manrope-Regular", sans-serif;
}
.procedureContainer .procedureContainer-content__elements .procedureContainer-content__number {
  font-size: 22rem;
  color: transparent;
  -webkit-text-stroke: 3px black;
  font-family: "Roboto-Bold", sans-serif;
  margin-left: 7%;
  margin-top: 0.1em;
  width: auto;
  height: auto;
  font-variant-numeric: slashed-zero;
}
.procedureContainer .procedureContainer-content__elements .procedureContainer-content-buttonContainer {
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 4rem;
  margin-right: calc(12.5% - 16px);
}
.procedureContainer .procedureContainer-content__elements .procedureContainer-content-buttonContainer .procedureContainer-content-buttonContainer__dottedButton {
  background-color: transparent;
  border: none;
  font-size: 1.8rem;
  font-family: "Montserrat", sans-serif;
  color: #14181D;
  cursor: pointer;
  padding: 0.5em;
  display: flex;
  align-items: center;
  transition: 0.5s;
}
.procedureContainer .procedureContainer-content__elements .procedureContainer-content-buttonContainer .procedureContainer-content-buttonContainer__dottedButton:hover {
  color: #E92428;
  transform: translateX(-1.5em);
}
.procedureContainer .procedureContainer-content__elements .procedureContainer-content-buttonContainer .procedureContainer-content-buttonContainer__dottedButton:hover .procedureContainer-content-buttonContainer__dottedButton__dot {
  transform: translateX(1.5em);
}
.procedureContainer .procedureContainer-content__elements .procedureContainer-content-buttonContainer .procedureContainer-content-buttonContainer__dottedButton__dot {
  margin-left: 1em;
  height: 1.6rem;
  width: 1.6rem;
  border: solid 3px #14181D;
  border-radius: 50%;
  background-color: #E6E6E6;
  transition: 0.5s;
}
.procedureContainer .procedureContainer-content__elements .procedureContainer-content-buttonContainer .activeDot {
  background-color: #14181D;
  transition: 0.5s;
}
.procedureContainer .procedureContainer-content__elements .procedureContainer-content-redCar {
  height: 300px;
  width: auto;
  position: absolute;
  bottom: 200px;
  right: 400px;
}
.procedureContainer .procedureContainer-content__elements .procedureContainer-content__lines__bottom {
  display: flex;
  position: absolute;
  bottom: 0;
  margin-left: 12.5%;
  height: 300px;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 2rem;
  z-index: 2;
}
.procedureContainer .procedureContainer-content__elements .procedureContainer-content__lines__bottom .procedureContainer-content__lines__blue {
  height: 12vh;
  width: 60px;
  background-color: #1C3A66;
  z-index: 3;
}
.procedureContainer .procedureContainer-content__elements .procedureContainer-content__lines__bottom .procedureContainer-content__lines__red {
  height: 12vh;
  width: 60px;
  background-color: #E92428;
  z-index: 3;
}
.procedureContainer .procedureContainer-content__lines {
  display: flex;
  margin-left: 12.5%;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;
  z-index: 1;
}
.procedureContainer .procedureContainer-content__lines .procedureContainer-content__lines__blue {
  height: 9vh;
  width: 60px;
  background-color: #1C3A66;
  z-index: 3;
}
.procedureContainer .procedureContainer-content__lines .procedureContainer-content__lines__red {
  height: 9vh;
  width: 60px;
  background-color: #E92428;
  z-index: 3;
}

@media (max-width: 767px) {
  .procedureContainer {
    height: 1050px;
  }
  .procedureContainer .h2Text {
    font-size: 2.5rem;
    top: 20px;
    left: 67%;
  }
  .procedureContainer .procedureContainer-content__elements {
    margin-top: 4em;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
  }
  .procedureContainer .procedureContainer-content__elements .procedureContainer-content-redCar {
    display: none;
  }
  .procedureContainer .procedureContainer-content__elements .procedureContainer-content__text {
    margin-top: 5em;
    margin-left: 5%;
    width: 80%;
    justify-content: flex-end;
  }
  .procedureContainer .procedureContainer-content__elements .procedureContainer-content__text .title {
    font-size: 2.5rem;
  }
  .procedureContainer .procedureContainer-content__elements .procedureContainer-content__text .text {
    font-size: 1.8rem;
  }
  .procedureContainer .procedureContainer-content__elements .procedureContainer-content__number {
    font-size: 27rem;
    position: absolute;
    top: 11%;
    margin-right: 20px;
    -webkit-text-stroke: 3px #D9D9D9;
  }
  .procedureContainer .procedureContainer-content__elements .procedureContainer-content-buttonContainer {
    gap: 2.5rem;
    margin-right: calc(7% - 15px);
  }
  .procedureContainer .procedureContainer-content__elements .procedureContainer-content-buttonContainer .procedureContainer-content-buttonContainer__dottedButton {
    font-size: 1.6rem;
  }
  .procedureContainer .procedureContainer-content__elements .procedureContainer-content__lines__bottom {
    gap: 1rem;
    margin-left: 5%;
  }
  .procedureContainer .procedureContainer-content__elements .procedureContainer-content__lines__bottom .procedureContainer-content__lines__blue {
    height: 100px;
    width: 40px;
  }
  .procedureContainer .procedureContainer-content__elements .procedureContainer-content__lines__bottom .procedureContainer-content__lines__red {
    height: 100px;
    width: 40px;
  }
  .procedureContainer .procedureContainer-content__lines {
    margin-left: 5%;
    gap: 1rem;
  }
  .procedureContainer .procedureContainer-content__lines .procedureContainer-content__lines__blue {
    height: 10vh;
    width: 40px;
  }
  .procedureContainer .procedureContainer-content__lines .procedureContainer-content__lines__red {
    height: 10vh;
    width: 40px;
  }
}
@media (max-width: 1088px) and (min-width: 768px) {
  .procedureContainer h2 {
    font-size: 4.5rem;
  }
  .procedureContainer .procedureContainer-content__elements {
    margin-top: 7em;
  }
  .procedureContainer .procedureContainer-content__elements .procedureContainer-content__lines__bottom {
    margin-left: 7%;
  }
  .procedureContainer .procedureContainer-content__elements .procedureContainer-content__lines__bottom .procedureContainer-content__lines__blue {
    height: 100px;
    width: 40px;
  }
  .procedureContainer .procedureContainer-content__elements .procedureContainer-content__lines__bottom .procedureContainer-content__lines__red {
    height: 100px;
    width: 40px;
  }
  .procedureContainer .procedureContainer-content__elements .procedureContainer-content-redCar {
    display: none;
  }
  .procedureContainer .procedureContainer-content__elements .procedureContainer-content__text {
    margin-left: 7%;
  }
  .procedureContainer .procedureContainer-content__elements .procedureContainer-content__text .title {
    font-size: 2.5rem;
  }
  .procedureContainer .procedureContainer-content__elements .procedureContainer-content__text .text {
    font-size: 1.6rem;
  }
  .procedureContainer .procedureContainer-content__elements .procedureContainer-content__number {
    margin-left: 10px;
    font-size: 15rem;
  }
  .procedureContainer .procedureContainer-content__elements .procedureContainer-content-buttonContainer {
    gap: 2.5rem;
    margin-right: calc(4% + 0.5vw);
  }
  .procedureContainer .procedureContainer-content__elements .procedureContainer-content-buttonContainer .procedureContainer-content-buttonContainer__dottedButton {
    font-size: 1.6rem;
  }
  .procedureContainer .procedureContainer-content__lines {
    margin-left: 7%;
  }
  .procedureContainer .procedureContainer-content__lines .procedureContainer-content__lines__blue {
    height: 6vh;
    width: 40px;
  }
  .procedureContainer .procedureContainer-content__lines .procedureContainer-content__lines__red {
    height: 6vh;
    width: 40px;
  }
}
@media (max-width: 1088px) and (min-width: 768px) and (orientation: portrait) {
  .procedureContainer h2 {
    font-size: 5.5rem;
  }
  .procedureContainer .procedureContainer-content__elements {
    margin-top: 7em;
  }
  .procedureContainer .procedureContainer-content__elements .procedureContainer-content__lines__bottom {
    margin-left: 7%;
  }
  .procedureContainer .procedureContainer-content__elements .procedureContainer-content__lines__bottom .procedureContainer-content__lines__blue {
    height: 100px;
    width: 40px;
  }
  .procedureContainer .procedureContainer-content__elements .procedureContainer-content__lines__bottom .procedureContainer-content__lines__red {
    height: 100px;
    width: 40px;
  }
  .procedureContainer .procedureContainer-content__elements .procedureContainer-content-redCar {
    display: none;
  }
  .procedureContainer .procedureContainer-content__elements .procedureContainer-content__text {
    margin-left: 7%;
  }
  .procedureContainer .procedureContainer-content__elements .procedureContainer-content__text .title {
    font-size: 2.5rem;
  }
  .procedureContainer .procedureContainer-content__elements .procedureContainer-content__text .text {
    font-size: 1.6rem;
  }
  .procedureContainer .procedureContainer-content__elements .procedureContainer-content__number {
    margin-left: 10px;
    font-size: 15rem;
  }
  .procedureContainer .procedureContainer-content__elements .procedureContainer-content-buttonContainer {
    gap: 2.5rem;
    margin-right: calc(4% + 0.1vw);
  }
  .procedureContainer .procedureContainer-content__elements .procedureContainer-content-buttonContainer .procedureContainer-content-buttonContainer__dottedButton {
    font-size: 1.6rem;
  }
  .procedureContainer .procedureContainer-content__lines {
    margin-left: 7%;
  }
  .procedureContainer .procedureContainer-content__lines .procedureContainer-content__lines__blue {
    height: 6vh;
    width: 40px;
  }
  .procedureContainer .procedureContainer-content__lines .procedureContainer-content__lines__red {
    height: 6vh;
    width: 40px;
  }
}
@media (max-width: 1201px) and (min-width: 1089px) {
  .procedureContainer h2 {
    font-size: 4.5rem;
  }
  .procedureContainer .procedureContainer-content__elements {
    margin-top: 7em;
  }
  .procedureContainer .procedureContainer-content__elements .procedureContainer-content__lines__bottom {
    margin-left: 7%;
  }
  .procedureContainer .procedureContainer-content__elements .procedureContainer-content__lines__bottom .procedureContainer-content__lines__blue {
    height: 100px;
    width: 40px;
  }
  .procedureContainer .procedureContainer-content__elements .procedureContainer-content__lines__bottom .procedureContainer-content__lines__red {
    height: 100px;
    width: 40px;
  }
  .procedureContainer .procedureContainer-content__elements .procedureContainer-content-redCar {
    display: none;
  }
  .procedureContainer .procedureContainer-content__elements .procedureContainer-content__text {
    margin-left: 7%;
  }
  .procedureContainer .procedureContainer-content__elements .procedureContainer-content__text .title {
    font-size: 2.5rem;
  }
  .procedureContainer .procedureContainer-content__elements .procedureContainer-content__text .text {
    font-size: 1.6rem;
  }
  .procedureContainer .procedureContainer-content__elements .procedureContainer-content__number {
    margin-left: 10px;
    font-size: 15rem;
  }
  .procedureContainer .procedureContainer-content__elements .procedureContainer-content-buttonContainer {
    gap: 2.5rem;
    margin-right: calc(5% - 3px);
  }
  .procedureContainer .procedureContainer-content__elements .procedureContainer-content-buttonContainer .procedureContainer-content-buttonContainer__dottedButton {
    font-size: 1.6rem;
  }
  .procedureContainer .procedureContainer-content__lines {
    margin-left: 7%;
  }
  .procedureContainer .procedureContainer-content__lines .procedureContainer-content__lines__blue {
    height: 6vh;
    width: 40px;
  }
  .procedureContainer .procedureContainer-content__lines .procedureContainer-content__lines__red {
    height: 6vh;
    width: 40px;
  }
}
@media (max-width: 1600px) and (min-width: 1201px) {
  .procedureContainer h2 {
    font-size: 8rem;
  }
  .procedureContainer .procedureContainer-content__elements {
    margin-top: 70px;
  }
  .procedureContainer .procedureContainer-content__elements .procedureContainer-content-buttonContainer {
    gap: 3rem;
  }
  .procedureContainer .procedureContainer-content__elements .procedureContainer-content-redCar {
    height: 200px;
    bottom: 50px;
    right: 230px;
  }
}
@media (max-width: 1700px) and (min-width: 1601px) {
  .procedureContainer .procedureContainer-content__elements .procedureContainer-content-redCar {
    display: none;
  }
}
@media (max-width: 1920px) and (min-width: 1701px) {
  .procedureContainer {
    height: 1000px;
  }
  .procedureContainer h2 {
    font-size: 8rem;
  }
  .procedureContainer .procedureContainer-content__elements .procedureContainer-content-redCar {
    height: 230px;
    bottom: 70px;
    right: 330px;
  }
}
@media (max-width: 2100px) and (min-width: 1921px) {
  .procedureContainer {
    height: 1000px;
  }
  .procedureContainer h2 {
    font-size: 9rem;
  }
  .procedureContainer .procedureContainer-content__elements .procedureContainer-content-redCar {
    display: none;
  }
}
@media (max-width: 2320px) and (min-width: 2101px) {
  .procedureContainer {
    height: 900px;
  }
  .procedureContainer .procedureContainer-content__elements .procedureContainer-content-redCar {
    display: none;
  }
}
@media (min-width: 2321px) {
  .procedureContainer {
    height: 1150px;
  }
  .procedureContainer .procedureContainer-content__elements .procedureContainer-content__text .title {
    font-size: 6rem;
  }
  .procedureContainer .procedureContainer-content__elements .procedureContainer-content__text .text {
    font-size: 2.4rem;
  }
  .procedureContainer .procedureContainer-content__elements .procedureContainer-content-redCar {
    height: 300px;
    bottom: 150px;
    right: 500px;
  }
  .procedureContainer .procedureContainer-content__elements .procedureContainer-content-buttonContainer .procedureContainer-content-buttonContainer__dottedButton {
    font-size: 2rem;
  }
}
@media (min-width: 1201px) and (max-width: 1919px) {
  .procedureContainer .procedureContainer-content__lines {
    margin-left: 7%;
  }
  .procedureContainer .procedureContainer-content__elements .procedureContainer-content__text {
    margin-left: 7%;
  }
  .procedureContainer .procedureContainer-content__elements .procedureContainer-content-buttonContainer {
    margin-right: calc(7% - 16px);
  }
  .procedureContainer .procedureContainer-content__elements .procedureContainer-content__lines__bottom {
    margin-left: 7%;
  }
}

