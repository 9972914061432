@import "colors";


body {
  position: relative;
  margin: 0;
  padding: 0;
}

h2 {
  font-size: 4rem;
  font-family: "Montserrat-SemiBold", sans-serif;
  color: $mainFontColor;
}

.faqContainerBg {
  position: relative;
  width: 100%;
  margin-top: -9px;
  background-color: $gray1Color;
}

.faqContainer {
  margin-left: 12.5%;
  width: 75%;
  height: 100%;
  padding-top: 10em;

  .faqContainer-elements {
    margin-top: 4em ;
  }

  .procedureContainer-content__lines__bottom {
    margin-top: 10em;
    display: flex;
    bottom: 0;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 2rem;
    z-index: 2;

    .procedureContainer-content__lines__blue {
      height: 12vh;
      width: 60px;
      background-color: $blueColor;
      z-index: 3;
    }

    .procedureContainer-content__lines__red {
      height: 12vh;
      width: 60px;
      background-color: $redColor;
      z-index: 3;
    }
  }



}
.faqDescriptionContainer {
  margin-left: 12.5%;
  margin-top: 10em;
  width: 75%;
  background-color: $white2Color;

  h3 {
    margin: 1em 0;
    font-family: "Montserrat", sans-serif;
  }

  p {
    padding: 0.2em;
    color: $mainFontColor;
    font-size: 2rem;
    font-family: "Montserrat-Regular", sans-serif;
    width: 40%;
    margin-bottom: 2em;
  }

  .procedureContainer-content__lines__bottom {
    margin-top: 10em;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 2rem;
    z-index: 2;

    .procedureContainer-content__lines__blue {
      height: 5vh;
      width: 60px;
      background-color: $blueColor;
      z-index: 3;
    }

    .procedureContainer-content__lines__red {
      height: 5vh;
      width: 60px;
      background-color: $redColor;
      z-index: 3;
    }
  }
}

@media (max-width: 476px) {

  .faqH2 {
    font-size: 3rem;
    text-align: center;
  }

  .faqContainerBg {

    .faqContainer {
      margin-left: 5%;
      width: 90%;


      .procedureContainer-content__lines__bottom {
        gap: 1rem;

        .procedureContainer-content__lines__blue {
          height: 100px;
          width: 40px;
        }
        .procedureContainer-content__lines__red {
          height: 100px;
          width: 40px;
        }
      }
    }
  }

  .faqDescriptionContainer {
    margin-left: 5%;
    width: 90%;
    margin-top: 5em;

    h3 {
      font-size: 2.4rem;
    }

    p {
      width: 95%;
      font-size: 1.8rem;
    }

    .betterButton-container {
      justify-content: center;
    }

    .procedureContainer-content__lines__bottom {
      gap: 1rem;

      .procedureContainer-content__lines__red {
        height: 40px;
        width: 40px;
      }

      .procedureContainer-content__lines__blue {
        height: 40px;
        width: 40px;
      }
    }
  }
}
@media (max-width: 1200px) and (min-width: 477px) {
  .faqH2 {
    font-size: 4rem;
    text-align: center;
  }

  .faqContainerBg {

    .faqContainer {
      margin-left: 7%;
      width: 86%;
      padding-top: 3em;


      .procedureContainer-content__lines__bottom {
        gap: 1rem;

        .procedureContainer-content__lines__blue {
          height: 100px;
          width: 40px;
        }
        .procedureContainer-content__lines__red {
          height: 100px;
          width: 40px;
        }
      }
    }
  }

  .faqDescriptionContainer {
    margin-left: 7%;
    width: 86%;
    margin-top: 5em;

    h3 {
      font-size: 2.4rem;
    }

    p {
      width: 95%;
      font-size: 1.8rem;
    }

    .betterButton-container {
      justify-content: flex-start;
    }

    .procedureContainer-content__lines__bottom {
      gap: 1rem;

      .procedureContainer-content__lines__red {
        height: 40px;
        width: 40px;
      }

      .procedureContainer-content__lines__blue {
        height: 40px;
        width: 40px;
      }
    }
  }
}

@media (max-width: 1200px) and (min-width: 477px) and (orientation: portrait) {
  .faqContainerBg {

    .faqContainer {
      margin-left: 7%;
      width: 86%;
      padding-top: 10em;



    }
  }
}

@media (min-width: 1201px) and (max-width: 1919px) {

  .faqContainerBg {
    .faqContainer {
      margin-left: 7%;
      width: 86%;
      padding-top: 10em;
    }
  }

  .faqDescriptionContainer {
    margin-left: 7%;
    width: 86%;
  }

}
