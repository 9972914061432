body {
  position: relative;
  margin: 0;
  padding: 0;
}

h2 {
  font-size: 4rem;
  font-family: "Montserrat-SemiBold", sans-serif;
  color: #14181D;
}

.fade-in-out {
  animation-name: fade-in-out;
  animation-duration: 0.7s;
}

.contactContainerBg {
  position: relative;
  width: 100%;
  margin-top: -9px;
  background-color: #E6E6E6;
}
.contactContainerBg .contactContainer {
  margin-left: 12.5%;
  width: 75%;
  height: 100%;
  padding-top: 10em;
}
.contactContainerBg .contactContainer .contactContainer-elements {
  margin-top: 4em;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.contactContainerBg .contactContainer .contactContainer-elements .contactContainer-elements-cities {
  width: 54%;
  flex-wrap: wrap;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.contactContainerBg .contactContainer .contactContainer-elements .contactContainer-elements-cities .contactContainer-elements-cities__city {
  cursor: pointer;
  margin: 2em 4em 2em 0;
  display: flex;
  flex-wrap: wrap;
}
.contactContainerBg .contactContainer .contactContainer-elements .contactContainer-elements-cities .contactContainer-elements-cities__city .mobileMap {
  display: none;
}
.contactContainerBg .contactContainer .contactContainer-elements .contactContainer-elements-cities .contactContainer-elements-cities__city .contactContainer-elements-cities__city__info.active {
  background-color: #D9D9D9;
  transition: 0.5s;
}
.contactContainerBg .contactContainer .contactContainer-elements .contactContainer-elements-cities .contactContainer-elements-cities__city .contactContainer-elements-cities__city__info {
  width: 320px;
  height: 210px;
  padding: 1em 4em 1em 1em;
}
.contactContainerBg .contactContainer .contactContainer-elements .contactContainer-elements-cities .contactContainer-elements-cities__city .contactContainer-elements-cities__city__info .cityName {
  padding-bottom: 1em;
  font-size: 2rem;
  font-family: "Montserrat-SemiBold", sans-serif;
  color: #14181D;
}
.contactContainerBg .contactContainer .contactContainer-elements .contactContainer-elements-cities .contactContainer-elements-cities__city .contactContainer-elements-cities__city__info p {
  padding-bottom: 0.3em;
  font-family: "Montserrat-Regular", sans-serif;
  color: #14181D;
  font-size: 2rem;
}
.contactContainerBg .contactContainer .contactContainer-elements .contactContainer-elements-cities .contactContainer-elements-cities__city .contactContainer-elements-cities__city__lines {
  display: flex;
  opacity: 0;
  transition: 0.5s;
}
.contactContainerBg .contactContainer .contactContainer-elements .contactContainer-elements-cities .contactContainer-elements-cities__city .contactContainer-elements-cities__city__lines .cityLine-blue {
  width: 10px;
  height: 100%;
  background-color: #E92428;
}
.contactContainerBg .contactContainer .contactContainer-elements .contactContainer-elements-cities .contactContainer-elements-cities__city .contactContainer-elements-cities__city__lines .cityLine-red {
  width: 10px;
  height: 100%;
  background-color: #1C3A66;
}
.contactContainerBg .contactContainer .contactContainer-elements .contactContainer-elements-cities .contactContainer-elements-cities__city .contactContainer-elements-cities__city__lines.activeLine {
  opacity: 1;
}
.contactContainerBg .contactContainer .contactContainer-elements img {
  width: 450px;
  height: auto;
}
.contactContainerBg .contactContainer .procedureContainer-content__lines__bottom {
  display: flex;
  margin-top: 7em;
  bottom: 0;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 2rem;
  z-index: 2;
}
.contactContainerBg .contactContainer .procedureContainer-content__lines__bottom .procedureContainer-content__lines__blue {
  height: 12vh;
  width: 60px;
  background-color: #1C3A66;
  z-index: 3;
}
.contactContainerBg .contactContainer .procedureContainer-content__lines__bottom .procedureContainer-content__lines__red {
  height: 12vh;
  width: 60px;
  background-color: #E92428;
  z-index: 3;
}

.emailContainer {
  margin-left: 12.5%;
  margin-top: 10em;
  width: 75%;
  background-color: #f0f0f0;
}
.emailContainer h3 {
  margin: 1em 0 2em;
  font-family: "Montserrat", sans-serif;
}
.emailContainer form {
  display: flex;
  justify-content: space-between;
}
.emailContainer label {
  color: #707070;
  display: block;
  font-size: 2rem;
  margin: 1em 0;
  font-family: "Montserrat", sans-serif;
}
.emailContainer label ::-webkit-scrollbar {
  display: none;
}
.emailContainer textarea {
  resize: none;
  margin-top: 0.6em;
  height: 360px;
  width: 600px;
  display: block;
  border: none;
  background-color: #D9D9D9;
  -webkit-appearance: none;
  font-size: 1.8rem;
  color: #14181D;
  padding-left: 5px;
  font-family: "Montserrat", sans-serif;
}
.emailContainer input {
  margin-top: 0.6em;
  height: 50px;
  width: 350px;
  display: block;
  border: none;
  background-color: #D9D9D9;
  -webkit-appearance: none;
  font-size: 1.8rem;
  color: #14181D;
  padding-left: 5px;
  font-family: "Montserrat", sans-serif;
}
.emailContainer textarea:focus {
  outline: none;
}
.emailContainer input:focus {
  outline: none;
}
.emailContainer select:focus {
  outline: none;
}
.emailContainer .buttonContainer {
  display: flex;
}
.emailContainer .procedureContainer-content__lines__bottom {
  margin-top: 10em;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 2rem;
  z-index: 2;
}
.emailContainer .procedureContainer-content__lines__bottom .procedureContainer-content__lines__blue {
  height: 5vh;
  width: 60px;
  background-color: #1C3A66;
  z-index: 3;
}
.emailContainer .procedureContainer-content__lines__bottom .procedureContainer-content__lines__red {
  height: 5vh;
  width: 60px;
  background-color: #E92428;
  z-index: 3;
}

@media (max-width: 476px) {
  .contactH2 {
    font-size: 3em;
    text-align: center;
  }

  .contactContainerBg .contactContainer {
    margin-left: 5%;
    width: 90%;
  }
  .contactContainerBg .contactContainer .contactContainer-elements {
    width: 100%;
    justify-content: flex-start;
  }
  .contactContainerBg .contactContainer .contactContainer-elements .bigMap {
    display: none;
  }
  .contactContainerBg .contactContainer .contactContainer-elements .contactContainer-elements-cities {
    width: 100%;
  }
  .contactContainerBg .contactContainer .contactContainer-elements .contactContainer-elements-cities .contactContainer-elements-cities__city {
    margin: 0;
    padding: 0;
    display: flex;
  }
  .contactContainerBg .contactContainer .contactContainer-elements .contactContainer-elements-cities .contactContainer-elements-cities__city .contactContainer-elements-cities__city__info.active {
    background-color: #E6E6E6;
  }
  .contactContainerBg .contactContainer .contactContainer-elements .contactContainer-elements-cities .contactContainer-elements-cities__city .contactContainer-elements-cities__city__info {
    padding: 1em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .contactContainerBg .contactContainer .contactContainer-elements .contactContainer-elements-cities .contactContainer-elements-cities__city .contactContainer-elements-cities__city__info .mobileMap {
    height: 110px;
    width: 110px;
    display: block;
  }
  .contactContainerBg .contactContainer .contactContainer-elements .contactContainer-elements-cities .contactContainer-elements-cities__city .contactContainer-elements-cities__city__info p {
    font-size: 1.8rem;
  }
  .contactContainerBg .contactContainer .procedureContainer-content__lines__bottom {
    gap: 1rem;
  }
  .contactContainerBg .contactContainer .procedureContainer-content__lines__bottom .procedureContainer-content__lines__blue {
    height: 100px;
    width: 40px;
  }
  .contactContainerBg .contactContainer .procedureContainer-content__lines__bottom .procedureContainer-content__lines__red {
    height: 100px;
    width: 40px;
  }

  .emailContainer {
    margin-top: 5em;
    margin-left: 5%;
    width: 90%;
  }
  .emailContainer form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .emailContainer label {
    font-size: 1.8rem;
    width: 100%;
  }
  .emailContainer input {
    width: 100%;
  }
  .emailContainer textarea {
    width: 100%;
  }
  .emailContainer .buttonContainer {
    margin-top: 3em;
    justify-content: center;
  }
  .emailContainer .procedureContainer-content__lines__bottom {
    gap: 1rem;
  }
  .emailContainer .procedureContainer-content__lines__bottom .procedureContainer-content__lines__blue {
    height: 40px;
    width: 40px;
  }
  .emailContainer .procedureContainer-content__lines__bottom .procedureContainer-content__lines__red {
    height: 40px;
    width: 40px;
  }
}
@media (max-width: 1200px) and (min-width: 477px) {
  .contactH2 {
    font-size: 4em;
    text-align: center;
  }

  .contactContainerBg .contactContainer {
    margin-left: 7%;
    width: 86%;
    padding-top: 3em;
  }
  .contactContainerBg .contactContainer .contactContainer-elements {
    width: 100%;
    justify-content: start;
  }
  .contactContainerBg .contactContainer .contactContainer-elements .bigMap {
    display: none;
  }
  .contactContainerBg .contactContainer .contactContainer-elements .contactContainer-elements-cities {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .contactContainerBg .contactContainer .contactContainer-elements .contactContainer-elements-cities .contactContainer-elements-cities__city {
    margin: 0;
    width: 45%;
    padding: 0;
    display: flex;
  }
  .contactContainerBg .contactContainer .contactContainer-elements .contactContainer-elements-cities .contactContainer-elements-cities__city .contactContainer-elements-cities__city__info.active {
    background-color: #E6E6E6;
  }
  .contactContainerBg .contactContainer .contactContainer-elements .contactContainer-elements-cities .contactContainer-elements-cities__city .contactContainer-elements-cities__city__info {
    padding: 1em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .contactContainerBg .contactContainer .contactContainer-elements .contactContainer-elements-cities .contactContainer-elements-cities__city .contactContainer-elements-cities__city__info .mobileMap {
    height: 110px;
    width: 110px;
    display: block;
  }
  .contactContainerBg .contactContainer .contactContainer-elements .contactContainer-elements-cities .contactContainer-elements-cities__city .contactContainer-elements-cities__city__info p {
    font-size: 1.8rem;
  }
  .contactContainerBg .contactContainer .procedureContainer-content__lines__bottom {
    gap: 1rem;
  }
  .contactContainerBg .contactContainer .procedureContainer-content__lines__bottom .procedureContainer-content__lines__blue {
    height: 100px;
    width: 40px;
  }
  .contactContainerBg .contactContainer .procedureContainer-content__lines__bottom .procedureContainer-content__lines__red {
    height: 100px;
    width: 40px;
  }

  .emailContainer {
    margin-top: 5em;
    margin-left: 7%;
    width: 86%;
  }
  .emailContainer form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .emailContainer label {
    font-size: 1.8rem;
    width: 100%;
  }
  .emailContainer input {
    width: 100%;
  }
  .emailContainer textarea {
    width: 100%;
  }
  .emailContainer .buttonContainer {
    margin-top: 3em;
    justify-content: flex-end;
  }
  .emailContainer .procedureContainer-content__lines__bottom {
    gap: 1rem;
  }
  .emailContainer .procedureContainer-content__lines__bottom .procedureContainer-content__lines__blue {
    height: 40px;
    width: 40px;
  }
  .emailContainer .procedureContainer-content__lines__bottom .procedureContainer-content__lines__red {
    height: 40px;
    width: 40px;
  }
}
@media (max-width: 1200px) and (min-width: 477px) and (orientation: portrait) {
  .contactContainerBg .contactContainer {
    margin-left: 7%;
    width: 86%;
    padding-top: 10em;
  }
  .contactContainerBg .contactContainer .contactContainer-elements {
    width: 100%;
    justify-content: start;
  }
  .contactContainerBg .contactContainer .contactContainer-elements .bigMap {
    display: none;
  }
  .contactContainerBg .contactContainer .contactContainer-elements .contactContainer-elements-cities {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .contactContainerBg .contactContainer .contactContainer-elements .contactContainer-elements-cities .contactContainer-elements-cities__city {
    margin: 0;
    width: 100%;
    padding: 0;
    display: flex;
  }
  .contactContainerBg .contactContainer .contactContainer-elements .contactContainer-elements-cities .contactContainer-elements-cities__city .contactContainer-elements-cities__city__info.active {
    background-color: #E6E6E6;
  }
  .contactContainerBg .contactContainer .contactContainer-elements .contactContainer-elements-cities .contactContainer-elements-cities__city .contactContainer-elements-cities__city__info {
    padding: 1em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .contactContainerBg .contactContainer .contactContainer-elements .contactContainer-elements-cities .contactContainer-elements-cities__city .contactContainer-elements-cities__city__info .mobileMap {
    height: 110px;
    width: 110px;
    display: block;
  }

  .emailContainer {
    margin-top: 5em;
    margin-left: 7%;
    width: 86%;
  }
}
@media (min-width: 1201px) and (max-width: 1919px) {
  .contactContainerBg .contactContainer {
    margin-left: 7%;
    width: 86%;
  }
  .contactContainerBg .contactContainer .contactContainer-elements .contactContainer-elements-cities {
    width: 65%;
  }
  .emailContainer {
    margin-left: 7%;
    width: 86%;
  }
}

