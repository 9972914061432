.aboutUsContainer {
  width: 100%;
  top: -73px;
  background-color: #f0f0f0;
  position: relative;
  z-index: 3;
}
.aboutUsContainer h2 {
  font-size: 7rem;
  text-transform: uppercase;
  font-family: "Manrope-SemiBold", sans-serif;
  color: rgba(0, 0, 0, 0.05);
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.aboutUsContainer .aboutUsContainer-content {
  display: flex;
  justify-content: space-between;
  margin-top: calc(10em + 110px);
}
.aboutUsContainer .aboutUsContainer-content .aboutUsContainer-content__text {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  width: 30%;
  margin-left: 12.5%;
  transition: 0.5s;
}
.aboutUsContainer .aboutUsContainer-content .aboutUsContainer-content__text img {
  margin-top: 100px;
  width: 730px;
  margin-left: -5%;
}
.aboutUsContainer .aboutUsContainer-content .aboutUsContainer-content__text .title {
  font-size: 4rem;
  font-family: "Montserrat-SemiBold", sans-serif;
}
.aboutUsContainer .aboutUsContainer-content .aboutUsContainer-content__text .text {
  font-size: 2rem;
  font-family: "Manrope-Regular", sans-serif;
}
.aboutUsContainer .aboutUsContainer-content .aboutUsContainer-content-carousel {
  width: 35%;
  margin-right: 12.5%;
}
.aboutUsContainer .aboutUsContainer-squares {
  display: flex;
  justify-content: space-between;
  margin: 100px 12% 130px;
  flex-wrap: wrap;
}
.aboutUsContainer .aboutUsContainer-squares .aboutUsContainer-squares__square:hover .aboutUsContainer-squares-textContainer .aboutUsContainer-squares__mainText {
  color: #E92428;
}
.aboutUsContainer .aboutUsContainer-squares .aboutUsContainer-squares__square {
  width: 330px;
  height: 250px;
  background-color: #D9D9D9;
  display: flex;
  flex-direction: column;
  margin: 10px;
  z-index: 4;
}
.aboutUsContainer .aboutUsContainer-squares .aboutUsContainer-squares__square .aboutUsContainer-squares-textContainer {
  margin-top: 55px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.aboutUsContainer .aboutUsContainer-squares .aboutUsContainer-squares__square .aboutUsContainer-squares-textContainer .aboutUsContainer-squares__mainText {
  font-size: 7rem;
  font-family: "Montserrat-SemiBold", sans-serif;
  color: #1C3A66;
  transition: 0.5s;
}
.aboutUsContainer .aboutUsContainer-squares .aboutUsContainer-squares__square .aboutUsContainer-squares-textContainer .aboutUsContainer-squares__text {
  display: inline-block;
  text-align: center;
  font-size: 1.8rem;
  font-family: "Montserrat-SemiBold", sans-serif;
  color: #14181D;
  padding: 1em;
}
.aboutUsContainer .aboutUsContainer-lines__bottom {
  display: flex;
  position: absolute;
  bottom: 0;
  margin-left: 12.5%;
  height: 300px;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 2rem;
  z-index: 2;
}
.aboutUsContainer .aboutUsContainer-lines__bottom .aboutUsContainer-lines__blue {
  height: 5vh;
  width: 60px;
  background-color: #1C3A66;
  z-index: 3;
}
.aboutUsContainer .aboutUsContainer-lines__bottom .aboutUsContainer-lines__red {
  height: 5vh;
  width: 60px;
  background-color: #E92428;
  z-index: 3;
}

@media (max-width: 767px) {
  .aboutUsContainer h2 {
    font-size: 2.5rem;
    top: 20px;
  }
  .aboutUsContainer .aboutUsContainer-content {
    margin-top: 75px;
    flex-direction: column;
  }
  .aboutUsContainer .aboutUsContainer-content .aboutUsContainer-content__text {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 5em;
  }
  .aboutUsContainer .aboutUsContainer-content .aboutUsContainer-content__text .betterButton-container {
    display: flex;
    margin-top: 20px;
    margin-bottom: 15px;
  }
  .aboutUsContainer .aboutUsContainer-content .aboutUsContainer-content__text .title {
    font-size: 2.5rem;
  }
  .aboutUsContainer .aboutUsContainer-content .aboutUsContainer-content__text .text {
    font-size: 1.8rem;
  }
  .aboutUsContainer .aboutUsContainer-content .aboutUsContainer-content__text .blueCar {
    display: none;
  }
  .aboutUsContainer .aboutUsContainer-content .aboutUsContainer-content-carousel {
    margin-top: 50px;
    width: 90%;
    margin-left: 5%;
  }
  .aboutUsContainer .aboutUsContainer-squares {
    margin-top: 75px;
    margin-left: 5%;
    width: 90%;
  }
  .aboutUsContainer .aboutUsContainer-squares .aboutUsContainer-squares__square {
    width: 43%;
    height: 170px;
  }
  .aboutUsContainer .aboutUsContainer-squares .aboutUsContainer-squares__square .aboutUsContainer-squares-textContainer {
    margin-top: 40px;
  }
  .aboutUsContainer .aboutUsContainer-squares .aboutUsContainer-squares__square .aboutUsContainer-squares-textContainer .aboutUsContainer-squares__mainText {
    font-size: 4rem;
  }
  .aboutUsContainer .aboutUsContainer-squares .aboutUsContainer-squares__square .aboutUsContainer-squares-textContainer .aboutUsContainer-squares__text {
    font-size: 1.5rem;
  }
  .aboutUsContainer .aboutUsContainer-lines__bottom {
    gap: 1rem;
    margin-left: 5%;
  }
  .aboutUsContainer .aboutUsContainer-lines__bottom .aboutUsContainer-lines__blue {
    height: 60px;
    width: 40px;
  }
  .aboutUsContainer .aboutUsContainer-lines__bottom .aboutUsContainer-lines__red {
    height: 60px;
    width: 40px;
  }
}
@media (max-width: 1200px) and (min-width: 768px) {
  .aboutUsContainer .aboutUsContainer-content .aboutUsContainer-content__text {
    margin-left: 7%;
    width: 40%;
  }
  .aboutUsContainer .aboutUsContainer-content .aboutUsContainer-content__text p.title {
    margin-top: 2em;
    font-size: 2.5rem;
  }
  .aboutUsContainer .aboutUsContainer-content .aboutUsContainer-content__text p.text {
    font-size: 1.6rem;
  }
  .aboutUsContainer .aboutUsContainer-content .aboutUsContainer-content__text .blueCar {
    display: none;
  }
  .aboutUsContainer .aboutUsContainer-content .aboutUsContainer-content-carousel {
    width: 40%;
    margin-right: 7%;
  }
  .aboutUsContainer .aboutUsContainer-squares {
    margin-top: 75px;
    margin-left: 6%;
    width: 88%;
  }
  .aboutUsContainer .aboutUsContainer-squares .aboutUsContainer-squares__square {
    width: 43%;
    height: 170px;
  }
  .aboutUsContainer .aboutUsContainer-squares .aboutUsContainer-squares__square .aboutUsContainer-squares-textContainer {
    margin-top: 40px;
  }
  .aboutUsContainer .aboutUsContainer-squares .aboutUsContainer-squares__square .aboutUsContainer-squares-textContainer .aboutUsContainer-squares__mainText {
    font-size: 4rem;
  }
  .aboutUsContainer .aboutUsContainer-squares .aboutUsContainer-squares__square .aboutUsContainer-squares-textContainer .aboutUsContainer-squares__text {
    font-size: 1.5rem;
  }
  .aboutUsContainer .aboutUsContainer-lines__bottom {
    gap: 1rem;
    margin-left: 7%;
  }
  .aboutUsContainer .aboutUsContainer-lines__bottom .aboutUsContainer-lines__blue {
    height: 60px;
    width: 40px;
  }
  .aboutUsContainer .aboutUsContainer-lines__bottom .aboutUsContainer-lines__red {
    height: 60px;
    width: 40px;
  }
}
@media (max-width: 1200px) and (min-width: 768px) and (orientation: portrait) {
  .aboutUsContainer h2 {
    font-size: 5.5rem;
  }
  .aboutUsContainer .aboutUsContainer-content {
    flex-direction: column;
    margin-top: 75px;
  }
  .aboutUsContainer .aboutUsContainer-content .aboutUsContainer-content__text {
    margin-left: 7%;
    width: 40%;
  }
  .aboutUsContainer .aboutUsContainer-content .aboutUsContainer-content__text .blueCar {
    display: none;
  }
  .aboutUsContainer .aboutUsContainer-content .aboutUsContainer-content-carousel {
    margin-top: 4em;
    width: 86%;
    margin-left: 7%;
  }
  .aboutUsContainer .aboutUsContainer-squares {
    margin-top: 75px;
    margin-left: 6%;
    width: 88%;
  }
  .aboutUsContainer .aboutUsContainer-squares .aboutUsContainer-squares__square {
    width: 43%;
    height: 170px;
  }
  .aboutUsContainer .aboutUsContainer-squares .aboutUsContainer-squares__square .aboutUsContainer-squares-textContainer {
    margin-top: 40px;
  }
  .aboutUsContainer .aboutUsContainer-squares .aboutUsContainer-squares__square .aboutUsContainer-squares-textContainer .aboutUsContainer-squares__mainText {
    font-size: 4rem;
  }
  .aboutUsContainer .aboutUsContainer-squares .aboutUsContainer-squares__square .aboutUsContainer-squares-textContainer .aboutUsContainer-squares__text {
    font-size: 1.5rem;
  }
}
@media (max-width: 1600px) and (min-width: 1201px) {
  .aboutUsContainer .aboutUsContainer-content {
    margin-top: 150px;
  }
  .aboutUsContainer .aboutUsContainer-content .aboutUsContainer-content__text .title {
    font-size: 3.7rem;
  }
  .aboutUsContainer .aboutUsContainer-content .aboutUsContainer-content__text .blueCar {
    width: 600px;
    margin-left: -10%;
  }
  .aboutUsContainer .aboutUsContainer-squares {
    margin-top: 75px;
    margin-left: 12%;
    flex-wrap: nowrap;
  }
  .aboutUsContainer .aboutUsContainer-squares .aboutUsContainer-squares__square {
    width: 265px;
    height: 175px;
  }
  .aboutUsContainer .aboutUsContainer-squares .aboutUsContainer-squares__square .aboutUsContainer-squares-textContainer {
    margin-top: 40px;
  }
  .aboutUsContainer .aboutUsContainer-squares .aboutUsContainer-squares__square .aboutUsContainer-squares-textContainer .aboutUsContainer-squares__mainText {
    font-size: 5rem;
  }
  .aboutUsContainer .aboutUsContainer-squares .aboutUsContainer-squares__square .aboutUsContainer-squares-textContainer .aboutUsContainer-squares__text {
    font-size: 1.5rem;
  }
}
@media (min-width: 2321px) {
  .aboutUsContainer .aboutUsContainer-content .aboutUsContainer-content__text .title {
    font-size: 6rem;
  }
  .aboutUsContainer .aboutUsContainer-content .aboutUsContainer-content__text .text {
    font-size: 2.4rem;
  }
  .aboutUsContainer .aboutUsContainer-content .aboutUsContainer-content__text .blueCar {
    width: 850px;
    margin-left: 5%;
    margin-top: 25px;
  }
  .aboutUsContainer .aboutUsContainer-squares {
    margin-top: 25px;
    margin-left: 12%;
    flex-wrap: nowrap;
  }
  .aboutUsContainer .aboutUsContainer-squares .aboutUsContainer-squares__square {
    width: 465px;
    height: 250px;
  }
  .aboutUsContainer .aboutUsContainer-squares .aboutUsContainer-squares__square .aboutUsContainer-squares-textContainer {
    margin-top: 55px;
  }
  .aboutUsContainer .aboutUsContainer-squares .aboutUsContainer-squares__square .aboutUsContainer-squares-textContainer .aboutUsContainer-squares__mainText {
    font-size: 7rem;
  }
  .aboutUsContainer .aboutUsContainer-squares .aboutUsContainer-squares__square .aboutUsContainer-squares-textContainer .aboutUsContainer-squares__text {
    font-size: 2rem;
  }
}
@media (min-width: 1201px) and (max-width: 1919px) {
  .aboutUsContainer .aboutUsContainer-content .aboutUsContainer-content__text {
    margin-left: 7%;
  }
  .aboutUsContainer .aboutUsContainer-content .aboutUsContainer-content-carousel {
    margin-right: 7%;
  }
  .aboutUsContainer .aboutUsContainer-squares {
    margin-left: 7%;
    margin-right: 7%;
  }
  .aboutUsContainer .aboutUsContainer-lines__bottom {
    margin-left: 7%;
  }
}

