@import "colors";

.bodyView {

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}


.procedureContainer {
  //height: calc(100vw / 1.9);
  height: 800px;
  width: 100%;
  position: relative;
  top: -73px;
  //z-index: revert;
  background-color: $gray1Color;


  h2 {
    font-size: 7rem;
    text-transform: uppercase;
    font-family: "Manrope-SemiBold", sans-serif;
    color: $bgTextTransparentColor;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .procedureContainer-content__elements {
    display: flex;
    justify-content: space-between;
    height: 80%;

    //margin-left: 11.65%;
    //margin-right: 11.65%;
    margin-top: 10em;

    .fade-in-out {
      animation-name: fade-in-out;
      animation-duration: 0.7s;
    }

    @keyframes fade-in-out {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }


    .procedureContainer-content__text {
      display: flex;
      flex-direction: column;
      gap: 3rem;
      width: 30%;
      margin-left: 12.5%;
      transition: 0.5s;

      .title {
        font-size: 4rem;
        font-family: "Montserrat-SemiBold", sans-serif;
      }

      .text {
        font-size: 2rem;
        font-family: "Manrope-Regular", sans-serif;

      }

    }

    .procedureContainer-content__number {
      font-size: 22rem;
      color: transparent;
      -webkit-text-stroke: 3px black;
      font-family: "Roboto-Bold", sans-serif;
      margin-left: 7%;
      margin-top: 0.10em;
      width: auto;
      height: auto;
      font-variant-numeric: slashed-zero;

    }

    .procedureContainer-content-buttonContainer {
      flex-direction: column;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      gap: 4rem;
      margin-right: calc(12.5% - 16px);


      .procedureContainer-content-buttonContainer__dottedButton {
        background-color: transparent;
        border: none;
        font-size: 1.8rem;
        font-family: "Montserrat", sans-serif;
        color: $mainFontColor;
        cursor: pointer;
        padding: 0.5em;
        display: flex;
        align-items: center;
        transition: 0.5s;

      }

      .procedureContainer-content-buttonContainer__dottedButton:hover {

        color: $redColor;
        transform: translateX(-1.5em);

        .procedureContainer-content-buttonContainer__dottedButton__dot {
          transform: translateX(1.5em);
        }

      }

      .procedureContainer-content-buttonContainer__dottedButton__dot {
        margin-left: 1em;
        height: 1.6rem;
        width: 1.6rem;
        border: solid 3px $mainFontColor;
        border-radius: 50%;
        background-color: $gray1Color;
        transition: 0.5s;
      }

      .activeDot {
        background-color: $mainFontColor;
        transition: 0.5s;

      }
    }
    .procedureContainer-content-redCar {
      height: 300px;
      width: auto;
      position: absolute;
      bottom: 200px;
      right: 400px;
    }
    .procedureContainer-content__lines__bottom {
      display: flex;
      position: absolute;
      bottom: 0;
      margin-left: 12.5%;
      height: 300px;
      align-items: flex-end;
      justify-content: flex-start;
      gap: 2rem;
      z-index: 2;

      .procedureContainer-content__lines__blue {
        height: 12vh;
        width: 60px;
        background-color: $blueColor;
        z-index: 3;
      }

      .procedureContainer-content__lines__red {
        height: 12vh;
        width: 60px;
        background-color: $redColor;
        z-index: 3;
      }
    }
  }


  .procedureContainer-content__lines {
    display: flex;
    margin-left: 12.5%;
    align-items: center;
    justify-content: flex-start;
    gap: 2rem;
    z-index: 1;

    .procedureContainer-content__lines__blue {
      height: 9vh;
      width: 60px;
      background-color: $blueColor;
      z-index: 3;
    }

    .procedureContainer-content__lines__red {
      height: 9vh;
      width: 60px;
      background-color: $redColor;
      z-index: 3;
    }
  }
}


// MEDIA

//MOBILE DEVICES FROM 0 TO 475
@media (max-width: 767px) {

  .procedureContainer {
    height: 1050px;
    .h2Text {
      font-size: 2.5rem;
      top: 20px;
      left: 67%;
    }

    .procedureContainer-content__elements {
      margin-top: 4em;
      display: flex;
      flex-direction: column-reverse;
      justify-content: flex-end;
      .procedureContainer-content-redCar {
        display: none;
      }

      .procedureContainer-content__text {
        margin-top: 5em;
        margin-left: 5%;
        width: 80%;
        justify-content: flex-end;

        .title {
          font-size: 2.5rem;
        }

        .text {
          font-size: 1.8rem;
        }
      }
      .procedureContainer-content__number {
        font-size: 27rem;
        //display: none;
        position: absolute;
        top: 11%;
        margin-right: 20px;
        -webkit-text-stroke: 3px $gray2Color;
      }



      .procedureContainer-content-buttonContainer {
        gap: 2.5rem;
        margin-right: calc(7% - 15px);


        .procedureContainer-content-buttonContainer__dottedButton {
          font-size: 1.6rem;
        }
      }

      .procedureContainer-content__lines__bottom {
        gap: 1rem;
        margin-left: 5%;

        .procedureContainer-content__lines__blue {
          height: 100px;
          width: 40px;
        }

        .procedureContainer-content__lines__red {
          height: 100px;
          width: 40px;

        }
      }

    }

    .procedureContainer-content__lines {
      margin-left: 5%;
      gap: 1rem;
        .procedureContainer-content__lines__blue {
          height: 10vh;
          width: 40px;
        }

        .procedureContainer-content__lines__red {
          height: 10vh;
          width: 40px;

      }
    }
  }
}

//TABLETS FROM 476 TO 1200
@media (max-width: 1088px) and (min-width: 768px) {
  .procedureContainer {
    h2 {
      font-size: 4.5rem;
    }
    .procedureContainer-content__elements {
      margin-top: 7em;

      .procedureContainer-content__lines__bottom {
        margin-left: 7%;

        .procedureContainer-content__lines__blue {
          height: 100px;
          width: 40px;
        }

        .procedureContainer-content__lines__red {
          height: 100px;
          width: 40px;

        }
      }
      .procedureContainer-content-redCar {
        display: none;
      }

      .procedureContainer-content__text {
        margin-left: 7%;
        .title {
          font-size: 2.5rem;
        }

        .text {
          font-size: 1.6rem;
        }
      }
      .procedureContainer-content__number {
        margin-left: 10px;
        font-size: 15rem;
      }

      .procedureContainer-content-buttonContainer {
        gap: 2.5rem;
        margin-right: calc(4% + 0.5vw);

        .procedureContainer-content-buttonContainer__dottedButton {
          font-size: 1.6rem;
        }
      }
    }
    .procedureContainer-content__lines {
      margin-left: 7%;
      .procedureContainer-content__lines__blue {
        height: 6vh;
        width: 40px;
      }

      .procedureContainer-content__lines__red {
        height: 6vh;
        width: 40px;
      }
    }



  }
}

@media (max-width: 1088px) and (min-width: 768px) and (orientation: portrait) {
  .procedureContainer {
    h2 {

      font-size: 5.5rem;
    }
    .procedureContainer-content__elements {
      margin-top: 7em;

      .procedureContainer-content__lines__bottom {
        margin-left: 7%;

        .procedureContainer-content__lines__blue {
          height: 100px;
          width: 40px;
        }

        .procedureContainer-content__lines__red {
          height: 100px;
          width: 40px;

        }
      }
      .procedureContainer-content-redCar {
        display: none;
      }

      .procedureContainer-content__text {
        margin-left: 7%;
        .title {
          font-size: 2.5rem;
        }

        .text {
          font-size: 1.6rem;
        }
      }
      .procedureContainer-content__number {
        margin-left: 10px;
        font-size: 15rem;
      }

      .procedureContainer-content-buttonContainer {
        gap: 2.5rem;
        margin-right: calc(4% + 0.1vw);

        .procedureContainer-content-buttonContainer__dottedButton {
          font-size: 1.6rem;
        }
      }
    }
    .procedureContainer-content__lines {
      margin-left: 7%;
      .procedureContainer-content__lines__blue {
        height: 6vh;
        width: 40px;
      }

      .procedureContainer-content__lines__red {
        height: 6vh;
        width: 40px;
      }
    }



  }
}


@media (max-width: 1201px) and (min-width: 1089px) {

  .procedureContainer {
    h2 {
      font-size: 4.5rem;
    }
    .procedureContainer-content__elements {
      margin-top: 7em;

      .procedureContainer-content__lines__bottom {
        margin-left: 7%;

        .procedureContainer-content__lines__blue {
          height: 100px;
          width: 40px;
        }

        .procedureContainer-content__lines__red {
          height: 100px;
          width: 40px;

        }
      }
      .procedureContainer-content-redCar {
        display: none;
      }

      .procedureContainer-content__text {
        margin-left: 7%;
        .title {
          font-size: 2.5rem;
        }

        .text {
          font-size: 1.6rem;
        }
      }
      .procedureContainer-content__number {
        margin-left: 10px;
        font-size: 15rem;
      }

      .procedureContainer-content-buttonContainer {
        gap: 2.5rem;
        margin-right: calc(5% - 3px);

        .procedureContainer-content-buttonContainer__dottedButton {
          font-size: 1.6rem;
        }
      }
    }
    .procedureContainer-content__lines {
      margin-left: 7%;
      .procedureContainer-content__lines__blue {
        height: 6vh;
        width: 40px;
      }

      .procedureContainer-content__lines__red {
        height: 6vh;
        width: 40px;
      }
    }



  }
}
//LAPTOPS FROM 1201 TO 1600
@media (max-width: 1600px) and (min-width: 1201px){
  .procedureContainer {
    h2 {
      font-size: 8rem;
    }
    .procedureContainer-content__elements {
      margin-top: 70px;
      .procedureContainer-content-buttonContainer {
        gap: 3rem;
      }
      .procedureContainer-content-redCar {
        height: 200px;
        bottom: 50px;
        right: 230px;
      }
    }

  }
}

@media (max-width: 1700px) and (min-width: 1601px){
  .procedureContainer {
    h2 {
    }

    .procedureContainer-content__elements {
      .procedureContainer-content-redCar {
        display: none;
      }
    }
  }
}

@media (max-width: 1920px) and (min-width: 1701px) {
  .procedureContainer {
    height: 1000px;
    h2 {
      font-size: 8rem;
    }
    .procedureContainer-content__elements {
      .procedureContainer-content-redCar {
        height: 230px;
        bottom: 70px;
        right: 330px;
      }
    }
  }
}

@media (max-width: 2100px) and (min-width: 1921px) {
  .procedureContainer {
    height: 1000px;
    h2 {
      font-size: 9rem;
    }

    .procedureContainer-content__elements {
      .procedureContainer-content-redCar {
        display: none;
      }
    }
  }
}

@media (max-width: 2320px) and (min-width: 2101px) {
  .procedureContainer {
    height: 900px;

    h2 {
    }
    .procedureContainer-content__elements {
      .procedureContainer-content-redCar {
        display: none;
      }
    }
  }

}
// FOR 2K MONITORS
@media (min-width: 2321px) {
  .procedureContainer {
    height: 1150px;


    .procedureContainer-content__elements {
      .procedureContainer-content__text {
        .title {
          font-size: 6rem;
        }
        .text {
          font-size: 2.4rem;
        }
      }
      .procedureContainer-content-redCar {
        height: 300px;
        bottom: 150px;
        right: 500px;
      }

      .procedureContainer-content-buttonContainer {
        .procedureContainer-content-buttonContainer__dottedButton {
          font-size: 2rem;
        }
      }

    }
  }

}

@media (min-width: 1201px) and (max-width: 1919px)  {

  .procedureContainer {

    .procedureContainer-content__lines {
      margin-left: 7%;
    }

    .procedureContainer-content__elements {

      .procedureContainer-content__text {
        margin-left: 7%;
      }
      .procedureContainer-content-buttonContainer {
        margin-right: calc(7% - 16px);
      }
      .procedureContainer-content__lines__bottom {
        margin-left: 7%;
      }
    }
  }
}
