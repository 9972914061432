body {
  position: relative;
  margin: 0;
  padding: 0;
}

.procedureH2 {
  font-size: 4rem;
  font-family: "Montserrat-SemiBold", sans-serif;
  color: #14181D;
}

.procedureSubContainerBg {
  position: relative;
  width: 100%;
  margin-top: -9px;
  background-color: #E6E6E6;
}
.procedureSubContainerBg .procedureSubContainer {
  margin-left: 12.5%;
  width: 75%;
  height: 100%;
  padding-top: 10em;
}
.procedureSubContainerBg .procedureSubContainer .procedureSubContainer-elements {
  margin-top: 4em;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}
.procedureSubContainerBg .procedureSubContainer .procedureSubContainer-elements .procedureSubContainer-element {
  width: 45%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 7em;
}
.procedureSubContainerBg .procedureSubContainer .procedureSubContainer-elements .procedureSubContainer-element img {
  height: 100px;
  margin-right: 3em;
  width: auto;
}
.procedureSubContainerBg .procedureSubContainer .procedureSubContainer-elements .procedureSubContainer-element .procedureSubContainer-element__title {
  margin-top: 20px;
  margin-bottom: 0.2em;
  font-size: 2.6rem;
  font-family: "Montserrat-SemiBold", sans-serif;
  color: #14181D;
}
.procedureSubContainerBg .procedureSubContainer .procedureSubContainer-elements .procedureSubContainer-element .procedureSubContainer-element__text {
  margin-top: 3px;
  font-size: 2rem;
  font-family: "Manrope-Regular", sans-serif;
}
.procedureSubContainerBg .procedureSubContainer .procedureContainer-content__lines__bottom {
  display: flex;
  bottom: 0;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 2rem;
  z-index: 2;
}
.procedureSubContainerBg .procedureSubContainer .procedureContainer-content__lines__bottom .procedureContainer-content__lines__blue {
  height: 12vh;
  width: 60px;
  background-color: #1C3A66;
  z-index: 3;
}
.procedureSubContainerBg .procedureSubContainer .procedureContainer-content__lines__bottom .procedureContainer-content__lines__red {
  height: 12vh;
  width: 60px;
  background-color: #E92428;
  z-index: 3;
}

.youTubeContainerBg {
  position: relative;
  background-color: #f0f0f0;
  margin-left: 12.5%;
  width: 75%;
}
.youTubeContainerBg .youTubeContainer {
  padding-top: 10em;
}
.youTubeContainerBg .youTubeContainer .youTubeContainer-movie {
  margin-top: 7em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.youTubeContainerBg .youTubeContainer .youTubeContainer-movie iframe {
  margin-bottom: 10em;
}
.youTubeContainerBg .procedureContainer-content__lines__bottom {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 2rem;
  z-index: 2;
}
.youTubeContainerBg .procedureContainer-content__lines__bottom .procedureContainer-content__lines__blue {
  height: 5vh;
  width: 60px;
  background-color: #1C3A66;
  z-index: 3;
}
.youTubeContainerBg .procedureContainer-content__lines__bottom .procedureContainer-content__lines__red {
  height: 5vh;
  width: 60px;
  background-color: #E92428;
  z-index: 3;
}

@media (max-width: 476px) {
  .procedureH2 {
    font-size: 3rem;
    text-align: center;
  }

  .procedureSubContainerBg .procedureSubContainer {
    margin-left: 5%;
    width: 90%;
  }
  .procedureSubContainerBg .procedureSubContainer .procedureSubContainer-elements {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .procedureSubContainerBg .procedureSubContainer .procedureSubContainer-elements .procedureSubContainer-element {
    align-items: center;
    width: 100%;
    flex-direction: column;
    justify-content: center;
  }
  .procedureSubContainerBg .procedureSubContainer .procedureSubContainer-elements .procedureSubContainer-element img {
    margin: 0;
  }
  .procedureSubContainerBg .procedureSubContainer .procedureSubContainer-elements .procedureSubContainer-element .procedureSubContainer-element__title {
    margin-top: 20px;
    margin-bottom: 0.5em;
    font-size: 2.4rem;
  }
  .procedureSubContainerBg .procedureSubContainer .procedureSubContainer-elements .procedureSubContainer-element .procedureSubContainer-element__text {
    margin-top: 3px;
    font-size: 1.8rem;
  }
  .procedureSubContainerBg .procedureSubContainer .procedureContainer-content__lines__bottom {
    gap: 1rem;
  }
  .procedureSubContainerBg .procedureSubContainer .procedureContainer-content__lines__bottom .procedureContainer-content__lines__blue {
    height: 100px;
    width: 40px;
  }
  .procedureSubContainerBg .procedureSubContainer .procedureContainer-content__lines__bottom .procedureContainer-content__lines__red {
    height: 100px;
    width: 40px;
  }

  .youTubeContainerBg {
    margin-left: 5%;
    width: 90%;
  }
  .youTubeContainerBg .youTubeContainer {
    padding-top: 5em;
  }
  .youTubeContainerBg .youTubeContainer h2 {
    font-size: 3rem;
  }
  .youTubeContainerBg .youTubeContainer .youTubeContainer-movie {
    margin-top: 5em;
  }
  .youTubeContainerBg .youTubeContainer .youTubeContainer-movie iframe {
    width: 100%;
    height: 200px;
    margin-bottom: 5em;
  }
  .youTubeContainerBg .procedureContainer-content__lines__bottom {
    gap: 1rem;
  }
  .youTubeContainerBg .procedureContainer-content__lines__bottom .procedureContainer-content__lines__blue {
    height: 40px;
    width: 40px;
  }
  .youTubeContainerBg .procedureContainer-content__lines__bottom .procedureContainer-content__lines__red {
    height: 40px;
    width: 40px;
  }
}
@media (max-width: 1200px) and (min-width: 477px) {
  .procedureH2 {
    font-size: 4rem;
    text-align: center;
  }

  .procedureSubContainerBg .procedureSubContainer {
    margin-left: 7%;
    width: 86%;
    padding-top: 3em;
  }
  .procedureSubContainerBg .procedureSubContainer .procedureSubContainer-elements {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .procedureSubContainerBg .procedureSubContainer .procedureSubContainer-elements .procedureSubContainer-element {
    align-items: center;
    width: 100%;
    flex-direction: column;
    justify-content: center;
  }
  .procedureSubContainerBg .procedureSubContainer .procedureSubContainer-elements .procedureSubContainer-element img {
    margin: 0;
  }
  .procedureSubContainerBg .procedureSubContainer .procedureSubContainer-elements .procedureSubContainer-element .procedureSubContainer-element__title {
    margin-top: 20px;
    margin-bottom: 0.5em;
    font-size: 2.4rem;
  }
  .procedureSubContainerBg .procedureSubContainer .procedureSubContainer-elements .procedureSubContainer-element .procedureSubContainer-element__text {
    margin-top: 3px;
    font-size: 1.8rem;
  }
  .procedureSubContainerBg .procedureSubContainer .procedureContainer-content__lines__bottom {
    gap: 1rem;
  }
  .procedureSubContainerBg .procedureSubContainer .procedureContainer-content__lines__bottom .procedureContainer-content__lines__blue {
    height: 100px;
    width: 40px;
  }
  .procedureSubContainerBg .procedureSubContainer .procedureContainer-content__lines__bottom .procedureContainer-content__lines__red {
    height: 100px;
    width: 40px;
  }

  .youTubeContainerBg {
    margin-left: 7%;
    width: 86%;
  }
  .youTubeContainerBg .youTubeContainer {
    padding-top: 5em;
  }
  .youTubeContainerBg .youTubeContainer h2 {
    font-size: 3rem;
  }
  .youTubeContainerBg .youTubeContainer .youTubeContainer-movie {
    margin-top: 5em;
  }
  .youTubeContainerBg .youTubeContainer .youTubeContainer-movie iframe {
    width: 100%;
    height: 450px;
    margin-bottom: 5em;
  }
  .youTubeContainerBg .procedureContainer-content__lines__bottom {
    gap: 1rem;
  }
  .youTubeContainerBg .procedureContainer-content__lines__bottom .procedureContainer-content__lines__blue {
    height: 40px;
    width: 40px;
  }
  .youTubeContainerBg .procedureContainer-content__lines__bottom .procedureContainer-content__lines__red {
    height: 40px;
    width: 40px;
  }
}
@media (max-width: 1200px) and (min-width: 477px) and (orientation: portrait) {
  .procedureSubContainerBg .procedureSubContainer {
    padding-top: 10em;
  }

  .youTubeContainerBg .youTubeContainer .youTubeContainer-movie iframe {
    width: 100%;
    height: 350px;
    margin-bottom: 5em;
  }
}
@media (min-width: 1201px) and (max-width: 1919px) {
  .procedureSubContainerBg .procedureSubContainer {
    margin-left: 7%;
    width: 86%;
  }

  .youTubeContainerBg {
    margin-left: 7%;
    width: 86%;
  }
}

