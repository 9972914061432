body {
  position: relative;
  margin: 0;
  padding: 0;
}

h2 {
  font-size: 4rem;
  font-family: "Montserrat-SemiBold", sans-serif;
  color: #14181D;
}

.faqContainerBg {
  position: relative;
  width: 100%;
  margin-top: -9px;
  background-color: #E6E6E6;
}

.faqContainer {
  margin-left: 12.5%;
  width: 75%;
  height: 100%;
  padding-top: 10em;
}
.faqContainer .faqContainer-elements {
  margin-top: 4em;
}
.faqContainer .procedureContainer-content__lines__bottom {
  margin-top: 10em;
  display: flex;
  bottom: 0;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 2rem;
  z-index: 2;
}
.faqContainer .procedureContainer-content__lines__bottom .procedureContainer-content__lines__blue {
  height: 12vh;
  width: 60px;
  background-color: #1C3A66;
  z-index: 3;
}
.faqContainer .procedureContainer-content__lines__bottom .procedureContainer-content__lines__red {
  height: 12vh;
  width: 60px;
  background-color: #E92428;
  z-index: 3;
}

.faqDescriptionContainer {
  margin-left: 12.5%;
  margin-top: 10em;
  width: 75%;
  background-color: #f0f0f0;
}
.faqDescriptionContainer h3 {
  margin: 1em 0;
  font-family: "Montserrat", sans-serif;
}
.faqDescriptionContainer p {
  padding: 0.2em;
  color: #14181D;
  font-size: 2rem;
  font-family: "Montserrat-Regular", sans-serif;
  width: 40%;
  margin-bottom: 2em;
}
.faqDescriptionContainer .procedureContainer-content__lines__bottom {
  margin-top: 10em;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 2rem;
  z-index: 2;
}
.faqDescriptionContainer .procedureContainer-content__lines__bottom .procedureContainer-content__lines__blue {
  height: 5vh;
  width: 60px;
  background-color: #1C3A66;
  z-index: 3;
}
.faqDescriptionContainer .procedureContainer-content__lines__bottom .procedureContainer-content__lines__red {
  height: 5vh;
  width: 60px;
  background-color: #E92428;
  z-index: 3;
}

@media (max-width: 476px) {
  .faqH2 {
    font-size: 3rem;
    text-align: center;
  }

  .faqContainerBg .faqContainer {
    margin-left: 5%;
    width: 90%;
  }
  .faqContainerBg .faqContainer .procedureContainer-content__lines__bottom {
    gap: 1rem;
  }
  .faqContainerBg .faqContainer .procedureContainer-content__lines__bottom .procedureContainer-content__lines__blue {
    height: 100px;
    width: 40px;
  }
  .faqContainerBg .faqContainer .procedureContainer-content__lines__bottom .procedureContainer-content__lines__red {
    height: 100px;
    width: 40px;
  }

  .faqDescriptionContainer {
    margin-left: 5%;
    width: 90%;
    margin-top: 5em;
  }
  .faqDescriptionContainer h3 {
    font-size: 2.4rem;
  }
  .faqDescriptionContainer p {
    width: 95%;
    font-size: 1.8rem;
  }
  .faqDescriptionContainer .betterButton-container {
    justify-content: center;
  }
  .faqDescriptionContainer .procedureContainer-content__lines__bottom {
    gap: 1rem;
  }
  .faqDescriptionContainer .procedureContainer-content__lines__bottom .procedureContainer-content__lines__red {
    height: 40px;
    width: 40px;
  }
  .faqDescriptionContainer .procedureContainer-content__lines__bottom .procedureContainer-content__lines__blue {
    height: 40px;
    width: 40px;
  }
}
@media (max-width: 1200px) and (min-width: 477px) {
  .faqH2 {
    font-size: 4rem;
    text-align: center;
  }

  .faqContainerBg .faqContainer {
    margin-left: 7%;
    width: 86%;
    padding-top: 3em;
  }
  .faqContainerBg .faqContainer .procedureContainer-content__lines__bottom {
    gap: 1rem;
  }
  .faqContainerBg .faqContainer .procedureContainer-content__lines__bottom .procedureContainer-content__lines__blue {
    height: 100px;
    width: 40px;
  }
  .faqContainerBg .faqContainer .procedureContainer-content__lines__bottom .procedureContainer-content__lines__red {
    height: 100px;
    width: 40px;
  }

  .faqDescriptionContainer {
    margin-left: 7%;
    width: 86%;
    margin-top: 5em;
  }
  .faqDescriptionContainer h3 {
    font-size: 2.4rem;
  }
  .faqDescriptionContainer p {
    width: 95%;
    font-size: 1.8rem;
  }
  .faqDescriptionContainer .betterButton-container {
    justify-content: flex-start;
  }
  .faqDescriptionContainer .procedureContainer-content__lines__bottom {
    gap: 1rem;
  }
  .faqDescriptionContainer .procedureContainer-content__lines__bottom .procedureContainer-content__lines__red {
    height: 40px;
    width: 40px;
  }
  .faqDescriptionContainer .procedureContainer-content__lines__bottom .procedureContainer-content__lines__blue {
    height: 40px;
    width: 40px;
  }
}
@media (max-width: 1200px) and (min-width: 477px) and (orientation: portrait) {
  .faqContainerBg .faqContainer {
    margin-left: 7%;
    width: 86%;
    padding-top: 10em;
  }
}
@media (min-width: 1201px) and (max-width: 1919px) {
  .faqContainerBg .faqContainer {
    margin-left: 7%;
    width: 86%;
    padding-top: 10em;
  }

  .faqDescriptionContainer {
    margin-left: 7%;
    width: 86%;
  }
}

