@import "colors";
body {
  position: relative;
  margin: 0;
  padding: 0;

}
.procedureH2 {
  font-size: 4rem;
  font-family: "Montserrat-SemiBold", sans-serif;
  color: $mainFontColor;
}
.procedureSubContainerBg {
  position: relative;
  width: 100%;
  margin-top: -9px;
  background-color: $gray1Color;

  .procedureSubContainer {
    margin-left: 12.5%;
    width: 75%;
    height: 100%;
    padding-top: 10em;




    .procedureSubContainer-elements {
      margin-top: 4em;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;

      .procedureSubContainer-element {
        width: 45%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        //flex-basis: calc(33.33% - 4em);
        margin-bottom: 7em;

        img {
          height: 100px;
          margin-right: 3em;
          width: auto;
        }

        .procedureSubContainer-element__title {
          margin-top: 20px;
          margin-bottom: 0.2em;
          font-size: 2.6rem;
          font-family: "Montserrat-SemiBold", sans-serif;
          color: $mainFontColor;
        }

        .procedureSubContainer-element__text {
          margin-top: 3px;
          font-size: 2rem;
          font-family: "Manrope-Regular", sans-serif;
        }
      }
    }
    .procedureContainer-content__lines__bottom {
      display: flex;
      bottom: 0;
      align-items: flex-end;
      justify-content: flex-start;
      gap: 2rem;
      z-index: 2;

      .procedureContainer-content__lines__blue {
        height: 12vh;
        width: 60px;
        background-color: $blueColor;
        z-index: 3;
      }

      .procedureContainer-content__lines__red {
        height: 12vh;
        width: 60px;
        background-color: $redColor;
        z-index: 3;
      }
    }
  }


}
.youTubeContainerBg {
  position: relative;
  //height: 1070px;
  background-color: $white2Color;
  margin-left: 12.5%;
  width: 75%;

  .youTubeContainer {
    padding-top: 10em;

    .youTubeContainer-movie {
      margin-top: 7em;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      iframe {
        margin-bottom: 10em;
      }
    }
  }
  .procedureContainer-content__lines__bottom {
     display: flex;
     align-items: flex-end;
     justify-content: flex-start;
     gap: 2rem;
     z-index: 2;

     .procedureContainer-content__lines__blue {
       height: 5vh;
       width: 60px;
       background-color: $blueColor;
       z-index: 3;
     }

     .procedureContainer-content__lines__red {
       height: 5vh;
       width: 60px;
       background-color: $redColor;
       z-index: 3;
     }
   }
}

// Mobile
@media (max-width: 476px) {


  .procedureH2 {
    font-size: 3rem;
    text-align: center;

  }

  .procedureSubContainerBg {

    .procedureSubContainer {
      margin-left: 5%;
      width: 90%;

      .procedureSubContainer-elements {
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .procedureSubContainer-element {
          align-items: center;
          width: 100%;
          flex-direction: column;
          justify-content: center;

          img {
            margin: 0;
          }
          .procedureSubContainer-element__title {
            margin-top: 20px;
            margin-bottom: 0.5em;
            font-size: 2.4rem;

          }

          .procedureSubContainer-element__text {
            margin-top: 3px;
            font-size: 1.8rem;
          }
        }
      }
      .procedureContainer-content__lines__bottom {
        gap: 1rem;
        .procedureContainer-content__lines__blue {
          height: 100px;
          width: 40px;
        }
        .procedureContainer-content__lines__red {
          height: 100px;
          width: 40px;
        }
      }

    }
  }

  .youTubeContainerBg {
    margin-left: 5%;
    width: 90%;

    .youTubeContainer {
      padding-top: 5em;

      h2 {
        font-size: 3rem;
      }

      .youTubeContainer-movie {
        margin-top: 5em;

        iframe {
          width: 100%;
          height: 200px;
          margin-bottom: 5em;
        }
      }
    }

    .procedureContainer-content__lines__bottom {
      gap: 1rem;
      .procedureContainer-content__lines__blue {
        height: 40px;
        width: 40px;
      }
      .procedureContainer-content__lines__red {
        height: 40px;
        width: 40px;
      }
    }
  }
}

@media (max-width: 1200px) and (min-width: 477px) {
  .procedureH2 {
    font-size: 4rem;
    text-align: center;
  }
  .procedureSubContainerBg {

    .procedureSubContainer {
      margin-left: 7%;
      width: 86%;
      padding-top: 3em;

      .procedureSubContainer-elements {
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .procedureSubContainer-element {
          align-items: center;
          width: 100%;
          flex-direction: column;
          justify-content: center;

          img {
            margin: 0;
          }
          .procedureSubContainer-element__title {
            margin-top: 20px;
            margin-bottom: 0.5em;
            font-size: 2.4rem;

          }

          .procedureSubContainer-element__text {
            margin-top: 3px;
            font-size: 1.8rem;
          }
        }
      }
      .procedureContainer-content__lines__bottom {
        gap: 1rem;
        .procedureContainer-content__lines__blue {
          height: 100px;
          width: 40px;
        }
        .procedureContainer-content__lines__red {
          height: 100px;
          width: 40px;
        }
      }

    }
  }

  .youTubeContainerBg {
    margin-left: 7%;
    width: 86%;

    .youTubeContainer {
      padding-top: 5em;

      h2 {
        font-size: 3rem;
      }

      .youTubeContainer-movie {
        margin-top: 5em;

        iframe {
          width: 100%;
          height: 450px;
          margin-bottom: 5em;
        }
      }
    }

    .procedureContainer-content__lines__bottom {
      gap: 1rem;
      .procedureContainer-content__lines__blue {
        height: 40px;
        width: 40px;
      }
      .procedureContainer-content__lines__red {
        height: 40px;
        width: 40px;
      }
    }
  }
}


@media (max-width: 1200px) and (min-width: 477px) and (orientation: portrait) {

  .procedureSubContainerBg {
    .procedureSubContainer {
      padding-top: 10em;
    }
  }
  .youTubeContainerBg {

    .youTubeContainer {


      .youTubeContainer-movie {

        iframe {
          width: 100%;
          height: 350px;
          margin-bottom: 5em;
        }
      }
    }
  }
}

@media (min-width: 1201px) and (max-width: 1919px) {

  .procedureSubContainerBg {
    .procedureSubContainer {
      margin-left: 7%;
      width: 86%;
    }
  }

  .youTubeContainerBg {
    margin-left: 7%;
    width: 86%;
  }
}