body {
  position: relative;
  margin: 0;
  padding: 0;
}

h3 {
  font-size: 2.6rem;
  font-family: "Montserrat-SemiBold", sans-serif;
  color: #14181D;
}

.aboutUsSubContainerBg {
  position: relative;
  width: 100%;
  margin-top: -9px;
  background-color: #E6E6E6;
}
.aboutUsSubContainerBg .aboutUsSubContainer {
  margin-left: 12.5%;
  width: 75%;
  height: 100%;
  padding-top: 10em;
}
.aboutUsSubContainerBg .aboutUsSubContainer .aboutUsSubContainer-elements {
  margin-top: 4em;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}
.aboutUsSubContainerBg .aboutUsSubContainer .aboutUsSubContainer-elements .aboutUsContainer-elements__text {
  width: 40%;
  font-size: 2rem;
  font-family: "Montserrat-Regular", sans-serif;
}
.aboutUsSubContainerBg .aboutUsSubContainer .aboutUsSubContainer-elements .aboutUsContainer-elements__text p {
  margin-bottom: 2em;
}
.aboutUsSubContainerBg .aboutUsSubContainer .aboutUsSubContainer-elements .aboutUsContainer-elements__text .lastP {
  margin-bottom: 7em;
}
.aboutUsSubContainerBg .aboutUsSubContainer .aboutUsSubContainer-elements .aboutUsContainer-elements__text .procedureContainer-content__lines__bottom {
  display: flex;
  bottom: 0;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 2rem;
  z-index: 2;
}
.aboutUsSubContainerBg .aboutUsSubContainer .aboutUsSubContainer-elements .aboutUsContainer-elements__text .procedureContainer-content__lines__bottom .procedureContainer-content__lines__blue {
  height: 12vh;
  width: 60px;
  background-color: #1C3A66;
  z-index: 3;
}
.aboutUsSubContainerBg .aboutUsSubContainer .aboutUsSubContainer-elements .aboutUsContainer-elements__text .procedureContainer-content__lines__bottom .procedureContainer-content__lines__red {
  height: 12vh;
  width: 60px;
  background-color: #E92428;
  z-index: 3;
}
.aboutUsSubContainerBg .aboutUsSubContainer .aboutUsSubContainer-elements img {
  margin-top: 7em;
  width: 40vw;
}

.aboutUsDescriptionContainer {
  background-color: #f0f0f0;
  margin-top: 10em;
  margin-left: 12.5%;
  width: 75%;
}
.aboutUsDescriptionContainer .aboutUsDescriptionContainer-reviews {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.aboutUsDescriptionContainer .aboutUsDescriptionContainer-reviews .container-carousel-element {
  position: relative;
  background-color: #E6E6E6;
  width: 50%;
  height: 200px;
  font-size: 1.5rem;
  text-align: left;
  padding: 1.8em;
  font-family: "Montserrat-Regular", sans-serif;
  line-height: 1.6;
}
.aboutUsDescriptionContainer .aboutUsDescriptionContainer-reviews .container-carousel-element .container-carousel-element-smallContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 0;
  margin-bottom: 1.8em;
}
.aboutUsDescriptionContainer .aboutUsDescriptionContainer-reviews .container-carousel-element .container-carousel-element-smallContainer .container-carousel-element-smallContainer__name {
  font-weight: bold;
  color: #1C3A66;
  display: flex;
  justify-content: center;
  align-items: center;
}
.aboutUsDescriptionContainer .aboutUsDescriptionContainer-reviews .container-carousel-element .container-carousel-element-smallContainer .container-carousel-element-smallContainer__name .dot {
  height: 4px;
  width: 4px;
  border-radius: 50%;
  background-color: #1C3A66;
  margin-right: 7px;
}
.aboutUsDescriptionContainer .aboutUsDescriptionContainer-reviews .container-carousel-element .container-carousel-element-smallContainer .container-carousel-element-smallContainer__stars {
  display: flex;
  align-items: center;
  margin-right: 3.5em;
}
.aboutUsDescriptionContainer .odd {
  background-color: #D9D9D9 !important;
}
.aboutUsDescriptionContainer .aboutUsContainer-squares {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 10em 0;
}
.aboutUsDescriptionContainer .aboutUsContainer-squares .aboutUsContainer-squares__square:hover .aboutUsContainer-squares-textContainer .aboutUsContainer-squares__mainText {
  color: #E92428;
}
.aboutUsDescriptionContainer .aboutUsContainer-squares .aboutUsContainer-squares__square {
  width: 330px;
  height: 250px;
  background-color: #D9D9D9;
  display: flex;
  flex-direction: column;
  z-index: 4;
}
.aboutUsDescriptionContainer .aboutUsContainer-squares .aboutUsContainer-squares__square .aboutUsContainer-squares-textContainer {
  margin-top: 55px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.aboutUsDescriptionContainer .aboutUsContainer-squares .aboutUsContainer-squares__square .aboutUsContainer-squares-textContainer .aboutUsContainer-squares__mainText {
  font-size: 7rem;
  font-family: "Montserrat-SemiBold", sans-serif;
  color: #1C3A66;
  transition: 0.5s;
}
.aboutUsDescriptionContainer .aboutUsContainer-squares .aboutUsContainer-squares__square .aboutUsContainer-squares-textContainer .aboutUsContainer-squares__text {
  display: inline-block;
  text-align: center;
  font-size: 1.8rem;
  font-family: "Montserrat-SemiBold", sans-serif;
  color: #14181D;
  padding: 1em;
}
.aboutUsDescriptionContainer .procedureContainer-content__lines__bottom {
  margin-top: calc(10em - 3.3%);
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 2rem;
  z-index: 2;
}
.aboutUsDescriptionContainer .procedureContainer-content__lines__bottom .procedureContainer-content__lines__blue {
  height: 5vh;
  width: 60px;
  background-color: #1C3A66;
  z-index: 3;
}
.aboutUsDescriptionContainer .procedureContainer-content__lines__bottom .procedureContainer-content__lines__red {
  height: 5vh;
  width: 60px;
  background-color: #E92428;
  z-index: 3;
}

@media (max-width: 476px) {
  .aboutUsH2 {
    font-size: 3rem;
    text-align: center;
  }

  .aboutUsSubContainerBg .aboutUsSubContainer {
    margin-left: 5%;
    width: 90%;
  }
  .aboutUsSubContainerBg .aboutUsSubContainer .aboutUsSubContainer-elements .aboutUsContainer-elements__text {
    width: 100%;
    font-size: 1.8rem;
  }
  .aboutUsSubContainerBg .aboutUsSubContainer .aboutUsSubContainer-elements .aboutUsContainer-elements__text .procedureContainer-content__lines__bottom {
    gap: 1rem;
  }
  .aboutUsSubContainerBg .aboutUsSubContainer .aboutUsSubContainer-elements .aboutUsContainer-elements__text .procedureContainer-content__lines__bottom .procedureContainer-content__lines__blue {
    height: 100px;
    width: 40px;
  }
  .aboutUsSubContainerBg .aboutUsSubContainer .aboutUsSubContainer-elements .aboutUsContainer-elements__text .procedureContainer-content__lines__bottom .procedureContainer-content__lines__red {
    height: 100px;
    width: 40px;
  }
  .aboutUsSubContainerBg .aboutUsSubContainer .aboutUsSubContainer-elements img {
    display: none;
  }

  .aboutUsDescriptionContainer {
    margin-top: 5em;
    margin-left: 5%;
    width: 90%;
  }
  .aboutUsDescriptionContainer .aboutUsDescriptionContainer-reviews {
    flex-direction: column;
  }
  .aboutUsDescriptionContainer .aboutUsDescriptionContainer-reviews .container-carousel-element-smallContainer {
    width: 100%;
  }
  .aboutUsDescriptionContainer .aboutUsDescriptionContainer-reviews .container-carousel-element {
    font-size: 1.4rem;
    height: 250px;
    width: 100%;
  }
  .aboutUsDescriptionContainer .aboutUsDescriptionContainer-reviews .container-carousel-element .container-carousel-element-smallContainer {
    display: flex;
    position: absolute;
    justify-content: space-between;
    width: 86%;
  }
  .aboutUsDescriptionContainer .aboutUsDescriptionContainer-reviews .container-carousel-element .container-carousel-element-smallContainer .container-carousel-element-smallContainer__stars {
    margin: 0;
  }
  .aboutUsDescriptionContainer .second {
    display: none;
  }
  .aboutUsDescriptionContainer .forth {
    display: none;
  }
  .aboutUsDescriptionContainer .aboutUsContainer-squares {
    justify-content: space-around;
  }
  .aboutUsDescriptionContainer .aboutUsContainer-squares .aboutUsContainer-squares__square {
    width: 43%;
    height: 170px;
    margin: 10px;
  }
  .aboutUsDescriptionContainer .aboutUsContainer-squares .aboutUsContainer-squares__square .aboutUsContainer-squares-textContainer {
    margin-top: 40px;
  }
  .aboutUsDescriptionContainer .aboutUsContainer-squares .aboutUsContainer-squares__square .aboutUsContainer-squares-textContainer .aboutUsContainer-squares__mainText {
    font-size: 4rem;
  }
  .aboutUsDescriptionContainer .aboutUsContainer-squares .aboutUsContainer-squares__square .aboutUsContainer-squares-textContainer .aboutUsContainer-squares__text {
    font-size: 1.5rem;
  }
  .aboutUsDescriptionContainer .procedureContainer-content__lines__bottom {
    gap: 1rem;
  }
  .aboutUsDescriptionContainer .procedureContainer-content__lines__bottom .procedureContainer-content__lines__blue {
    height: 40px;
    width: 40px;
  }
  .aboutUsDescriptionContainer .procedureContainer-content__lines__bottom .procedureContainer-content__lines__red {
    height: 40px;
    width: 40px;
  }
}
@media (max-width: 1200px) and (min-width: 477px) {
  .aboutUsH2 {
    font-size: 4rem;
    text-align: center;
  }

  .aboutUsSubContainerBg .aboutUsSubContainer {
    margin-left: 7%;
    width: 86%;
    padding-top: 3em;
  }
  .aboutUsSubContainerBg .aboutUsSubContainer .aboutUsSubContainer-elements .aboutUsContainer-elements__text {
    width: 40%;
    font-size: 1.8rem;
  }
  .aboutUsSubContainerBg .aboutUsSubContainer .aboutUsSubContainer-elements .aboutUsContainer-elements__text .procedureContainer-content__lines__bottom {
    gap: 1rem;
  }
  .aboutUsSubContainerBg .aboutUsSubContainer .aboutUsSubContainer-elements .aboutUsContainer-elements__text .procedureContainer-content__lines__bottom .procedureContainer-content__lines__blue {
    height: 100px;
    width: 40px;
  }
  .aboutUsSubContainerBg .aboutUsSubContainer .aboutUsSubContainer-elements .aboutUsContainer-elements__text .procedureContainer-content__lines__bottom .procedureContainer-content__lines__red {
    height: 100px;
    width: 40px;
  }
  .aboutUsSubContainerBg .aboutUsSubContainer .aboutUsSubContainer-elements img {
    margin-top: 12em;
    width: 45vw;
  }

  .aboutUsDescriptionContainer {
    margin-top: 5em;
    margin-left: 7%;
    width: 86%;
  }
  .aboutUsDescriptionContainer .aboutUsDescriptionContainer-reviews {
    flex-direction: column;
  }
  .aboutUsDescriptionContainer .aboutUsDescriptionContainer-reviews .container-carousel-element-smallContainer {
    width: 100%;
  }
  .aboutUsDescriptionContainer .aboutUsDescriptionContainer-reviews .container-carousel-element {
    font-size: 1.4rem;
    height: 250px;
    width: 100%;
  }
  .aboutUsDescriptionContainer .aboutUsDescriptionContainer-reviews .container-carousel-element .container-carousel-element-smallContainer {
    display: flex;
    position: absolute;
    justify-content: space-between;
    width: 86%;
  }
  .aboutUsDescriptionContainer .aboutUsDescriptionContainer-reviews .container-carousel-element .container-carousel-element-smallContainer .container-carousel-element-smallContainer__stars {
    margin: 0;
  }
  .aboutUsDescriptionContainer .second {
    display: none;
  }
  .aboutUsDescriptionContainer .forth {
    display: none;
  }
  .aboutUsDescriptionContainer .aboutUsContainer-squares {
    justify-content: space-around;
  }
  .aboutUsDescriptionContainer .aboutUsContainer-squares .aboutUsContainer-squares__square {
    width: 43%;
    height: 170px;
    margin: 10px;
  }
  .aboutUsDescriptionContainer .aboutUsContainer-squares .aboutUsContainer-squares__square .aboutUsContainer-squares-textContainer {
    margin-top: 40px;
  }
  .aboutUsDescriptionContainer .aboutUsContainer-squares .aboutUsContainer-squares__square .aboutUsContainer-squares-textContainer .aboutUsContainer-squares__mainText {
    font-size: 4rem;
  }
  .aboutUsDescriptionContainer .aboutUsContainer-squares .aboutUsContainer-squares__square .aboutUsContainer-squares-textContainer .aboutUsContainer-squares__text {
    font-size: 1.5rem;
  }
  .aboutUsDescriptionContainer .procedureContainer-content__lines__bottom {
    gap: 1rem;
  }
  .aboutUsDescriptionContainer .procedureContainer-content__lines__bottom .procedureContainer-content__lines__blue {
    height: 40px;
    width: 40px;
  }
  .aboutUsDescriptionContainer .procedureContainer-content__lines__bottom .procedureContainer-content__lines__red {
    height: 40px;
    width: 40px;
  }
}
@media (max-width: 1200px) and (min-width: 477px) and (orientation: portrait) {
  .aboutUsSubContainerBg .aboutUsSubContainer {
    margin-left: 7%;
    width: 86%;
    padding-top: 10em;
  }
  .aboutUsSubContainerBg .aboutUsSubContainer .aboutUsSubContainer-elements .aboutUsContainer-elements__text {
    width: 100%;
    font-size: 1.8rem;
  }
  .aboutUsSubContainerBg .aboutUsSubContainer .aboutUsSubContainer-elements .aboutUsContainer-elements__text .procedureContainer-content__lines__bottom {
    gap: 1rem;
  }
  .aboutUsSubContainerBg .aboutUsSubContainer .aboutUsSubContainer-elements .aboutUsContainer-elements__text .procedureContainer-content__lines__bottom .procedureContainer-content__lines__blue {
    height: 100px;
    width: 40px;
  }
  .aboutUsSubContainerBg .aboutUsSubContainer .aboutUsSubContainer-elements .aboutUsContainer-elements__text .procedureContainer-content__lines__bottom .procedureContainer-content__lines__red {
    height: 100px;
    width: 40px;
  }
  .aboutUsSubContainerBg .aboutUsSubContainer .aboutUsSubContainer-elements img {
    display: none;
  }

  .aboutUsDescriptionContainer {
    margin-top: 5em;
    margin-left: 7%;
    width: 86%;
  }
  .aboutUsDescriptionContainer .aboutUsDescriptionContainer-reviews {
    flex-direction: column;
  }
  .aboutUsDescriptionContainer .aboutUsDescriptionContainer-reviews .container-carousel-element-smallContainer {
    width: 100%;
  }
  .aboutUsDescriptionContainer .aboutUsDescriptionContainer-reviews .container-carousel-element {
    font-size: 1.4rem;
    height: 250px;
    width: 100%;
  }
  .aboutUsDescriptionContainer .aboutUsDescriptionContainer-reviews .container-carousel-element .container-carousel-element-smallContainer {
    display: flex;
    position: absolute;
    justify-content: space-between;
    width: 86%;
  }
  .aboutUsDescriptionContainer .aboutUsDescriptionContainer-reviews .container-carousel-element .container-carousel-element-smallContainer .container-carousel-element-smallContainer__stars {
    margin: 0;
  }
  .aboutUsDescriptionContainer .second {
    display: none;
  }
  .aboutUsDescriptionContainer .forth {
    display: none;
  }
  .aboutUsDescriptionContainer .aboutUsContainer-squares {
    justify-content: space-around;
  }
  .aboutUsDescriptionContainer .aboutUsContainer-squares .aboutUsContainer-squares__square {
    width: 43%;
    height: 170px;
    margin: 10px;
  }
  .aboutUsDescriptionContainer .aboutUsContainer-squares .aboutUsContainer-squares__square .aboutUsContainer-squares-textContainer {
    margin-top: 40px;
  }
  .aboutUsDescriptionContainer .aboutUsContainer-squares .aboutUsContainer-squares__square .aboutUsContainer-squares-textContainer .aboutUsContainer-squares__mainText {
    font-size: 4rem;
  }
  .aboutUsDescriptionContainer .aboutUsContainer-squares .aboutUsContainer-squares__square .aboutUsContainer-squares-textContainer .aboutUsContainer-squares__text {
    font-size: 1.5rem;
  }
}
@media (min-width: 1201px) and (max-width: 1919px) {
  .aboutUsSubContainerBg .aboutUsSubContainer {
    margin-left: 7%;
    width: 86%;
  }

  .aboutUsDescriptionContainer {
    margin-top: 5em;
    margin-left: 7%;
    width: 86%;
  }
  .aboutUsDescriptionContainer .aboutUsContainer-squares {
    justify-content: space-around;
  }
  .aboutUsDescriptionContainer .aboutUsContainer-squares .aboutUsContainer-squares__square {
    width: 45%;
    height: 170px;
    margin: 10px;
  }
  .aboutUsDescriptionContainer .aboutUsContainer-squares .aboutUsContainer-squares__square .aboutUsContainer-squares-textContainer {
    margin-top: 40px;
  }
  .aboutUsDescriptionContainer .aboutUsContainer-squares .aboutUsContainer-squares__square .aboutUsContainer-squares-textContainer .aboutUsContainer-squares__mainText {
    font-size: 4rem;
  }
  .aboutUsDescriptionContainer .aboutUsContainer-squares .aboutUsContainer-squares__square .aboutUsContainer-squares-textContainer .aboutUsContainer-squares__text {
    font-size: 1.5rem;
  }
}
@media (min-width: 1921px) {
  .aboutUsSubContainerBg .aboutUsSubContainer .aboutUsSubContainer-elements img {
    margin-top: 0;
  }
}

