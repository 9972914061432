@import "colors";


.container {
  overflow: hidden;

  .container-carousel {


    .container-carousel-element {
      position: relative;
      background-color: $gray1Color;
      width: 10px;
      height: 200px;
      font-size: 1.5rem;
      text-align: left;
      padding: 1.8em;
      margin: -1px;
      font-family: "Montserrat-Regular", sans-serif;
      line-height: 1.6;

      .container-carousel-element-smallContainer {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        bottom: 0;
        margin-bottom: 1.8em;

        .container-carousel-element-smallContainer__name {
          font-weight: bold;
          color: $blueColor;
          display: flex;
          justify-content: center;
          align-items: center;

          .dot {
            height: 4px;
            width: 4px;
            border-radius: 50%;
            background-color: $blueColor;
            margin-right: 7px;

          }
        }

        .container-carousel-element-smallContainer__stars {
          //margin-right: 4em;
          display: flex;
          align-items: center;
          margin-right: 3.5em;

        }
      }
    }

    .odd {
      background-color: $gray2Color;
    }
  }


}

// MEDIA

@media (max-width: 476px) {
  .container {
    .container-carousel {

      .container-carousel-element {
        font-size: 1.4rem;
        height: 250px;
      }

    }
  }


}


@media (max-width: 1088px) and (min-width: 768px) {
  .container {
    .container-carousel {

      .container-carousel-element {
        font-size: 1.6rem;
        height: 270px;
      }

    }
  }
}
// LAPTOPS
@media (max-width: 1600px) and (min-width: 1201px) {
  .container {
    .container-carousel {

      .container-carousel-element {
        font-size: 1.6rem;
        height: 260px;
      }
    }
  }
}

// 2K MONITORS
@media (min-width: 2321px) {
    .container {
      .container-carousel {

        .container-carousel-element {
          font-size: 1.8rem;
        }
      }
    }
  
}


