body {
  position: relative;
  margin: 0;
  padding: 0;
}

h2 {
  font-size: 4rem;
  font-family: "Montserrat-SemiBold", sans-serif;
  color: #14181D;
}

.realizationsContainerBg {
  position: relative;
  width: 100%;
  margin-top: -9px;
  background-color: #f0f0f0;
}
.realizationsContainerBg .realizationsContainer {
  margin-left: 12.5%;
  width: 75%;
  height: 100%;
  padding-top: 10em;
}
.realizationsContainerBg .realizationsContainer .realizationsContainer-elements {
  margin-top: 4em;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.realizationsContainerBg .realizationsContainer .realizationsContainer-elements .zoom-wrapper {
  width: 31%;
  height: 280px;
  object-position: bottom;
  margin-bottom: 3.3%;
  overflow: hidden;
  transition: transform 1.8s;
}
.realizationsContainerBg .realizationsContainer .realizationsContainer-elements .zoom-wrapper .scale-container:hover .realizationsContainer-element {
  transition: transform 0.5s;
  transform: scale(1.2);
}
.realizationsContainerBg .realizationsContainer .realizationsContainer-elements .zoom-wrapper .realizationsContainer-element {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.realizationsContainerBg .realizationsContainer .procedureContainer-content__lines__bottom {
  margin-top: calc(10em - 3.3%);
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 2rem;
  z-index: 2;
}
.realizationsContainerBg .realizationsContainer .procedureContainer-content__lines__bottom .procedureContainer-content__lines__blue {
  height: 5vh;
  width: 60px;
  background-color: #1C3A66;
  z-index: 3;
}
.realizationsContainerBg .realizationsContainer .procedureContainer-content__lines__bottom .procedureContainer-content__lines__red {
  height: 5vh;
  width: 60px;
  background-color: #E92428;
  z-index: 3;
}

@media (max-width: 476px) {
  .realizationsH2 {
    font-size: 3rem;
    text-align: center;
  }

  .realizationsContainerBg .realizationsContainer {
    margin-left: 5%;
    width: 90%;
  }
  .realizationsContainerBg .realizationsContainer .realizationsContainer-elements .zoom-wrapper {
    width: 100%;
    height: 280px;
    object-position: bottom;
    margin-bottom: 5%;
    overflow: hidden;
  }
  .realizationsContainerBg .realizationsContainer .realizationsContainer-elements .zoom-wrapper .realizationsContainer-element {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
  .realizationsContainerBg .realizationsContainer .procedureContainer-content__lines__bottom {
    margin-top: 5em;
    gap: 1rem;
  }
  .realizationsContainerBg .realizationsContainer .procedureContainer-content__lines__bottom .procedureContainer-content__lines__red {
    width: 40px;
    height: 40px;
  }
  .realizationsContainerBg .realizationsContainer .procedureContainer-content__lines__bottom .procedureContainer-content__lines__blue {
    width: 40px;
    height: 40px;
  }
}
@media (max-width: 1200px) and (min-width: 477px) {
  .realizationsH2 {
    font-size: 4rem;
    text-align: center;
  }

  .realizationsContainerBg .realizationsContainer {
    margin-left: 7%;
    width: 86%;
    padding-top: 3em;
  }
  .realizationsContainerBg .realizationsContainer .realizationsContainer-elements .lastCarImg {
    display: none;
  }
  .realizationsContainerBg .realizationsContainer .realizationsContainer-elements .zoom-wrapper {
    width: 48%;
    height: 280px;
    object-position: bottom;
    margin-bottom: 3.3%;
    overflow: hidden;
  }
  .realizationsContainerBg .realizationsContainer .realizationsContainer-elements .zoom-wrapper .realizationsContainer-element {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
  .realizationsContainerBg .realizationsContainer .procedureContainer-content__lines__bottom {
    margin-top: 5em;
    gap: 1rem;
  }
  .realizationsContainerBg .realizationsContainer .procedureContainer-content__lines__bottom .procedureContainer-content__lines__red {
    width: 40px;
    height: 40px;
  }
  .realizationsContainerBg .realizationsContainer .procedureContainer-content__lines__bottom .procedureContainer-content__lines__blue {
    width: 40px;
    height: 40px;
  }
}
@media (max-width: 1200px) and (min-width: 477px) and (orientation: portrait) {
  .realizationsContainerBg .realizationsContainer {
    margin-left: 7%;
    width: 86%;
    padding-top: 10em;
  }
}
@media (min-width: 1921px) {
  .realizationsContainerBg .realizationsContainer .realizationsContainer-elements .zoom-wrapper {
    height: 380px;
    object-position: bottom;
    margin-bottom: 3.3%;
    overflow: hidden;
  }
}
@media (min-width: 1201px) and (max-width: 1919px) {
  .realizationsContainerBg .realizationsContainer {
    margin-left: 7%;
    width: 86%;
  }
}

