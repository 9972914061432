.betterButton-container {
  display: flex;
}
.betterButton-container .betterButton-container__blue {
  background-color: #1C3A66;
  height: 100%;
  width: 15px;
}
.betterButton-container .betterButton-container__red {
  background-color: #E92428;
  height: 100%;
  width: 15px;
  transition: 0.3s;
}
.betterButton-container .betterButton-container-button {
  background-color: #D9D9D9;
  border: none;
  width: 10em;
  font-size: 2rem;
  height: 2.5em;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  position: relative;
  z-index: 0;
}
.betterButton-container .betterButton-container-button .betterButton-text {
  transition: 0.3s;
  z-index: 1;
  font-size: 1.8rem;
  color: #14181D;
  font-family: "Montserrat", sans-serif;
}

.betterButton-container-button,
.betterButton-container-button::after,
.betterButton-container-button::before {
  transition: transform 0.3s ease-in-out;
}

.betterButton-container-button::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-color: #f0f0f0;
  transform: scaleX(0);
  transform-origin: right;
}

.betterButton-container-button:hover::after {
  transform: scaleX(100%);
  transform-origin: left;
}

