.heroViewContainer {
  width: 100%;
  height: 100%;
  bottom: 70px;
  position: relative;
}
.heroViewContainer .videoLoop {
  width: 100%;
  height: 100%;
  object-fit: cover;
  flex-direction: column;
  z-index: -10;
  min-height: 600px;
}
.heroViewContainer .videoLoopMobile {
  width: 100%;
  height: 100%;
  object-fit: cover;
  flex-direction: column;
  z-index: -10;
  display: none;
}
.heroViewContainer .heroViewContainer-content__fullHD-mainLine {
  z-index: 2;
  position: absolute;
  top: 80%;
  margin-left: 12.5%;
  width: 75%;
  pointer-events: none;
}
.heroViewContainer .heroViewContainer-content__quadFull-mainLine {
  z-index: 2;
  position: absolute;
  top: calc(100% - 20vh);
  margin-left: 12.5%;
  width: 75%;
  pointer-events: none;
  display: none;
}
.heroViewContainer .heroViewContainer-content__quadHD-mainLine {
  z-index: 2;
  position: absolute;
  top: calc(100% - 20vh);
  margin-left: 12.5%;
  width: 75%;
  pointer-events: none;
  display: none;
}
.heroViewContainer .heroViewContainer-content__mobile-mainLine {
  z-index: 2;
  position: absolute;
  top: 80%;
  margin-left: 12.5%;
  width: 75%;
  pointer-events: none;
  display: none;
}
.heroViewContainer .heroViewContainer-content__tablet-mainLine {
  z-index: 2;
  position: absolute;
  top: 80%;
  margin-left: 12.5%;
  width: 75%;
  pointer-events: none;
  display: none;
}
.heroViewContainer .heroViewContainer-content {
  width: 100%;
  height: 40%;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-start;
  color: #f0f0f0;
}
.heroViewContainer .heroViewContainer-content h1 {
  z-index: 1;
  font-size: 5rem;
  color: #14181D;
  margin-left: 12.5%;
  margin-bottom: 20px;
  font-family: "Montserrat-SemiBold", sans-serif;
  font-weight: normal;
}
.heroViewContainer .heroViewContainer-content .heroViewContainer-content__lines {
  margin-left: 12.5%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  z-index: 1;
}
.heroViewContainer .heroViewContainer-content .heroViewContainer-content__lines .heroViewContainer-content__lines__blue {
  height: 20vh;
  width: 60px;
  background-color: #1C3A66;
  z-index: 3;
}
.heroViewContainer .heroViewContainer-content .heroViewContainer-content__lines .heroViewContainer-content__lines__red {
  height: 20vh;
  width: 60px;
  background-color: #E92428;
  z-index: 3;
}

@media (max-width: 432px) {
  .heroViewContainer {
    height: 700px;
  }
  .heroViewContainer .heroViewContainer-content__fullHD-mainLine {
    display: none;
  }
  .heroViewContainer .heroViewContainer-content__quadFull-mainLine {
    display: none;
  }
  .heroViewContainer .heroViewContainer-content__quadHD-mainLine {
    display: none;
  }
  .heroViewContainer .heroViewContainer-content__tablet-mainLine {
    display: none;
  }
  .heroViewContainer .heroViewContainer-content__mobile-mainLine {
    display: block;
    margin-left: 5%;
    top: calc(100% - 15vh - 1px);
    width: 88%;
  }
  .heroViewContainer .videoLoop {
    display: none;
  }
  .heroViewContainer .videoLoopMobile {
    display: block;
  }
  .heroViewContainer .heroViewContainer-content .heroViewContainer-content__lines {
    margin-left: 5%;
    gap: 1rem;
  }
  .heroViewContainer .heroViewContainer-content .heroViewContainer-content__lines .heroViewContainer-content__lines__blue {
    width: 40px;
    height: 15vh;
  }
  .heroViewContainer .heroViewContainer-content .heroViewContainer-content__lines .heroViewContainer-content__lines__red {
    width: 40px;
    height: 15vh;
  }
  .heroViewContainer .heroViewContainer-content h1 {
    font-size: 2.5rem;
    margin-left: 5%;
  }
}
@media (max-width: 767px) and (min-width: 433px) {
  .heroViewContainer {
    height: 700px;
  }
  .heroViewContainer .heroViewContainer-content__fullHD-mainLine {
    display: none;
  }
  .heroViewContainer .heroViewContainer-content__quadFull-mainLine {
    display: none;
  }
  .heroViewContainer .heroViewContainer-content__quadHD-mainLine {
    display: none;
  }
  .heroViewContainer .heroViewContainer-content__tablet-mainLine {
    display: block;
    margin-left: 5%;
    top: calc(100% - 15vh - 1px);
    width: 88%;
  }
  .heroViewContainer .heroViewContainer-content__mobile-mainLine {
    display: none;
  }
  .heroViewContainer .videoLoop {
    display: none;
  }
  .heroViewContainer .videoLoopMobile {
    display: block;
  }
  .heroViewContainer .heroViewContainer-content .heroViewContainer-content__lines {
    margin-left: 5%;
    gap: 1rem;
  }
  .heroViewContainer .heroViewContainer-content .heroViewContainer-content__lines .heroViewContainer-content__lines__blue {
    width: 40px;
    height: 15vh;
  }
  .heroViewContainer .heroViewContainer-content .heroViewContainer-content__lines .heroViewContainer-content__lines__red {
    width: 40px;
    height: 15vh;
  }
  .heroViewContainer .heroViewContainer-content h1 {
    font-size: 2.5rem;
    margin-left: 5%;
  }
}
@media (max-width: 1200px) and (min-width: 768px) {
  .heroViewContainer .heroViewContainer-content__fullHD-mainLine {
    display: none;
  }
  .heroViewContainer .heroViewContainer-content__quadFull-mainLine {
    display: none;
  }
  .heroViewContainer .heroViewContainer-content__quadHD-mainLine {
    display: none;
  }
  .heroViewContainer .heroViewContainer-content__mobile-mainLine {
    display: none;
  }
  .heroViewContainer .heroViewContainer-content__tablet-mainLine {
    display: block;
    top: calc(100% - 10vh - 1px);
    width: 87%;
    margin-left: 7%;
  }
  .heroViewContainer .heroViewContainer-content h1 {
    margin-left: 7%;
  }
  .heroViewContainer .heroViewContainer-content .heroViewContainer-content__lines {
    margin-left: 7%;
  }
  .heroViewContainer .heroViewContainer-content .heroViewContainer-content__lines .heroViewContainer-content__lines__blue {
    width: 40px;
    height: 10vh;
  }
  .heroViewContainer .heroViewContainer-content .heroViewContainer-content__lines .heroViewContainer-content__lines__red {
    width: 40px;
    height: 10vh;
  }
}
@media (max-width: 1600px) and (min-width: 1201px) {
  .heroViewContainer .heroViewContainer-content__fullHD-mainLine {
    display: none;
  }
  .heroViewContainer .heroViewContainer-content__quadFull-mainLine {
    display: block;
  }
  .heroViewContainer .heroViewContainer-content__quadHD-mainLine {
    display: none;
  }
}
@media (max-width: 2099px) and (min-width: 1601px) {
  .heroViewContainer .heroViewContainer-content__fullHD-mainLine {
    display: none;
  }
  .heroViewContainer .heroViewContainer-content__quadFull-mainLine {
    display: block;
  }
  .heroViewContainer .heroViewContainer-content__quadHD-mainLine {
    display: none;
  }
  .heroViewContainer .heroViewContainer-content h1 {
    font-size: 5.5rem;
  }
}
@media (min-width: 2100px) {
  .heroViewContainer {
    height: 1500px;
  }
  .heroViewContainer .heroViewContainer-content__fullHD-mainLine {
    display: none;
  }
  .heroViewContainer .heroViewContainer-content__quadFull-mainLine {
    display: none;
  }
  .heroViewContainer .heroViewContainer-content__quadHD-mainLine {
    display: block;
  }
  .heroViewContainer .heroViewContainer-content h1 {
    font-size: 6rem;
  }
}
@media (min-width: 1201px) and (max-width: 1919px) {
  .heroViewContainer .heroViewContainer-content__quadFull-mainLine {
    z-index: 2;
    position: absolute;
    top: calc(100% - 20vh);
    margin-left: 7%;
    width: 86%;
    pointer-events: none;
    display: block;
  }
  .heroViewContainer .heroViewContainer-content h1 {
    margin-left: 7%;
    width: 86%;
  }
  .heroViewContainer .heroViewContainer-content .heroViewContainer-content__lines {
    margin-left: 7%;
  }
}

