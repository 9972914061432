html, body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

}

::selection {
  background: #1C3A66;
  color: white;
}

::-moz-selection {
  background-color: #1C3A66;
  color: white;
}


body::-webkit-scrollbar{
  display: none;
}

html {
  font-size: 62.5%;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Montserrat';
  src: local(Montserrat-Medium), url("fonts/Montserrat-Medium.ttf");
}

@font-face {
  font-family: 'Montserrat-SemiBold';
  src: local(Montserrat-SemiBold), url("fonts/Montserrat-SemiBold.ttf");
}

@font-face {
  font-family: 'Montserrat-Regular';
  src: local(Montserrat-Regular), url("fonts/Montserrat-Regular.ttf");
}

@font-face {
  font-family: 'Manrope-SemiBold';
  src: local(Manrope-SemiBold), url("fonts/Manrope-VariableFont_wght.ttf");
}

@font-face {
  font-family: "Manrope-Regular";
  src: local(Manrope-Regular), url("fonts/Manrope-VariableFont_regular.ttf");
}

@font-face {
  font-family: "Roboto-Bold";
  src: local(Roboto-Bold), url("fonts/RobotoMono-VariableFont_wght.ttf");

}