@import "colors";

body {
  position: relative;
  margin: 0;
  padding: 0;
}

h3 {
  font-size: 2.6rem;
  font-family: "Montserrat-SemiBold", sans-serif;
  color: $mainFontColor;
}
h2 {
  font-size: 4rem;
  font-family: "Montserrat-SemiBold", sans-serif;
  color: $mainFontColor;
}
.calculatorSubContainerBg {
  position: relative;
  width: 100%;
  margin-top: -9px;
  background-color: $gray1Color;

  .calculatorSubContainer {
    margin-left: 12.5%;
    width: 75%;
    height: 100%;
    padding-top: 10em;

    .calculatorSubContainer-elements {
      margin-top: 4em;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;

    .calculatorSubContainer-elements__text {
      width: 40%;
      font-size: 2rem;
      font-family: "Montserrat-Regular", sans-serif;

      p {
        margin-bottom: 2em;
      }
    }
    .calculatorSubContainer-elements__calc {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      flex-direction: column;

      .betterButton-container {
        margin-top: 2em;
        display: flex;
      }
      input[type="number"]::-webkit-inner-spin-button,
      input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
      }
      input[type="number"] {
        -moz-appearance: textfield;
      }


      label {
        color: $secondFontColor;
        display: block;
        font-size: 2rem;
        margin: 1em 0;
        font-family: "Montserrat", sans-serif;

        ::-webkit-scrollbar {
          display: none;
        }


      }
      input {
        margin-top: 0.6em;
        height: 50px;
        width: 350px;
        display: block;
        border: none;
        background-color: $gray2Color;
        -webkit-appearance: none;
        font-size: 1.8rem ;
        color: $mainFontColor;
        padding-left: 5px;
        font-family: "Montserrat", sans-serif;
      }

      input:focus {
        outline: none;
      }
      select:focus {
        outline: none;
      }
      select {
        margin-top: 0.6em;
        height: 50px;
        width: 350px;
        display: block;
        border: none;
        background-color: $gray2Color;
        font-size: 1.8rem ;
        color: $mainFontColor;
        padding-left: 3px;
        font-family: "Montserrat", sans-serif;

      }

      p {
        margin-top: 1.5em;
        font-size: 2.4rem;
        font-family: "Montserrat", sans-serif;
      }
    }

      table {

        margin-top: 1.5em;
        margin-bottom: 7em;
        border-collapse: collapse;

        thead {

          tr {
            th {
              border: 1px solid $mainFontColor;
              padding: 0.7em 1.2em;
              width: 160px;
            }
          }
        }
        tbody {
          tr {
            margin: 0;
            padding: 0;
            td {
              text-align: center;
              border: 1px solid $mainFontColor;
              padding: 0.7em 1.2em;
            }
          }

        }
      }


    }

  }
  .procedureContainer-content__lines__bottom {
    display: flex;
    bottom: 0;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 2rem;
    z-index: 2;

    .procedureContainer-content__lines__blue {
      height: 12vh;
      width: 60px;
      background-color: $blueColor;
      z-index: 3;
    }

    .procedureContainer-content__lines__red {
      height: 12vh;
      width: 60px;
      background-color: $redColor;
      z-index: 3;
    }
  }
}
.calculatorDescriptionContainer {
  margin-left: 12.5%;
  margin-top: 10em;
  width: 75%;
  background-color: $white2Color;

  h3 {
    margin: 1em 0;
    font-family: "Montserrat", sans-serif;
  }
  li {
    margin-left: 2em;
    padding: 0.2em;
    color: $mainFontColor;
    font-size: 2rem;
    font-family: "Montserrat-Regular", sans-serif;
  }

  .procedureContainer-content__lines__bottom {
    margin-top: 10em;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 2rem;
    z-index: 2;

    .procedureContainer-content__lines__blue {
      height: 5vh;
      width: 60px;
      background-color: $blueColor;
      z-index: 3;
    }

    .procedureContainer-content__lines__red {
      height: 5vh;
      width: 60px;
      background-color: $redColor;
      z-index: 3;
    }
  }
}

@media (max-width: 476px) {
  .calculatorH2 {
    font-size: 3rem;
    text-align: center;
  }

  .calculatorSubContainerBg {

    .calculatorSubContainer {
      margin-left: 5%;
      width: 90%;

      .calculatorSubContainer-elements {
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse;
        .calculatorSubContainer-elements__text {
          width: 100%;
          font-size: 1.8rem;

          h3 {
            font-size: 2.4rem;
          }
          table {
            margin-bottom: 5em;
            width: 100%;
          }
        }

        .calculatorSubContainer-elements__calc {
          align-items: center;
          margin-bottom: 1.5em;
          width: 100%;

          label {
            width: 100%;
          }
          input {
            width: 100%;
          }
          select {
            width: 100%;
          }
        }
      }
    }

    .procedureContainer-content__lines__bottom {
      gap: 1rem;

      .procedureContainer-content__lines__blue {
        height: 100px;
        width: 40px;
      }

      .procedureContainer-content__lines__red {
        height: 100px;
        width: 40px;
      }
    }
  }

  .calculatorDescriptionContainer {
    margin-left: 5%;
    width: 90%;
    margin-top: 5em;

    h3 {
      font-size: 2.4rem;
    }

    li {
      margin-left: 0.5em;
      font-size: 1.8rem;
    }

    .procedureContainer-content__lines__bottom {
      gap: 1rem;

      .procedureContainer-content__lines__blue {
        height: 40px;
        width: 40px;
      }
      .procedureContainer-content__lines__red {
        width: 40px;
        height: 40px;
      }
    }
  }
}


@media (max-width: 1200px) and (min-width: 477px) {
  .calculatorH2 {
    font-size: 4rem;
    text-align: center;
  }

  .calculatorSubContainerBg {

    .calculatorSubContainer {
      margin-left: 7%;
      width: 86%;
      padding-top: 3em;

      .calculatorSubContainer-elements {
        display: flex;
        .calculatorSubContainer-elements__text {
          width: 40%;
          font-size: 1.8rem;

          h3 {
            font-size: 2.4rem;
          }
          table {
            margin-bottom: 5em;
            width: 100%;
          }
        }

        .calculatorSubContainer-elements__calc {
          align-items: flex-end;
          margin-bottom: 1.5em;
          width: 50%;

          label {
            width: 100%;
          }
          input {
            width: 100%;
          }
          select {
            width: 100%;
          }
        }
      }
    }

    .procedureContainer-content__lines__bottom {
      gap: 1rem;

      .procedureContainer-content__lines__blue {
        height: 100px;
        width: 40px;
      }

      .procedureContainer-content__lines__red {
        height: 100px;
        width: 40px;
      }
    }
  }

  .calculatorDescriptionContainer {
    margin-left: 7%;
    width: 86%;
    margin-top: 5em;

    h3 {
      font-size: 2.4rem;
    }

    li {
      margin-left: 0.5em;
      font-size: 1.8rem;
    }

    .procedureContainer-content__lines__bottom {
      gap: 1rem;

      .procedureContainer-content__lines__blue {
        height: 40px;
        width: 40px;
      }
      .procedureContainer-content__lines__red {
        width: 40px;
        height: 40px;
      }
    }
  }
}

@media (max-width: 1200px) and (min-width: 477px)  and (orientation: portrait) {
  .calculatorH2 {
    font-size: 4rem;
    text-align: center;
  }

  .calculatorSubContainerBg {

    .calculatorSubContainer {
      margin-left: 7%;
      width: 86%;
      padding-top: 10em;

      .calculatorSubContainer-elements {
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse;

        .calculatorSubContainer-elements__text {
          width: 100%;
          font-size: 1.8rem;

          h3 {
            font-size: 2.4rem;
          }

          table {
            margin-bottom: 5em;
            width: 100%;
          }
        }

        .calculatorSubContainer-elements__calc {
          align-items: center;
          margin-bottom: 1.5em;
          width: 100%;

          label {
            width: 100%;
          }

          input {
            width: 100%;
          }

          select {
            width: 100%;
          }
        }
      }
    }

    .procedureContainer-content__lines__bottom {
      gap: 1rem;

      .procedureContainer-content__lines__blue {
        height: 100px;
        width: 40px;
      }

      .procedureContainer-content__lines__red {
        height: 100px;
        width: 40px;
      }
    }
  }

  .calculatorDescriptionContainer {
    margin-left: 7%;
    width: 86%;
    margin-top: 5em;

    h3 {
      font-size: 2.4rem;
    }

    li {
      margin-left: 0.5em;
      font-size: 1.8rem;
    }

    .procedureContainer-content__lines__bottom {
      gap: 1rem;

      .procedureContainer-content__lines__blue {
        height: 40px;
        width: 40px;
      }

      .procedureContainer-content__lines__red {
        width: 40px;
        height: 40px;
      }
    }
  }
}

@media (min-width: 1201px) and (max-width: 1919px) {
  .calculatorSubContainerBg {

    .calculatorSubContainer {
      margin-left: 7%;
      width: 86%;
    }
  }

  .calculatorDescriptionContainer {
    margin-left: 7%;
    width: 86%;
  }
  
}

