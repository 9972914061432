footer {
  width: 100%;
  background-color: #E6E6E6;
  position: relative;
  z-index: 0;
}
footer .logo__mobile {
  height: 75px;
  width: auto;
  display: none;
  margin-left: 50%;
  transform: translateX(-50%);
  margin-top: 3em;
}
footer .footer-line {
  display: none;
}
footer .footerContainer {
  margin-left: 12.5%;
  width: 75%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  padding: 2em 0;
}
footer .footerContainer .footerContainer-logoRow {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  gap: 3em;
}
footer .footerContainer .footerContainer-logoRow .logo {
  height: 100px;
  width: auto;
  margin-left: 30px;
  margin-top: 3em;
}
footer .footerContainer .footerContainer-logoRow p {
  color: #707070;
  font-family: "Montserrat", sans-serif;
  font-size: 1.8rem;
}
footer .footerContainer .footerContainer-logoRow .betterButton-container {
  display: flex;
  justify-content: space-between;
}
footer .footerContainer .footerContainer-routers {
  font-size: 2rem;
  justify-content: center;
  gap: 0.8em;
  display: flex;
  flex-direction: column;
}
footer .footerContainer .footerContainer-routers a {
  text-decoration: none;
  color: #707070;
  font-family: "Montserrat", sans-serif;
}
footer .footerContainer .footerContainer-contact {
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: 2em;
}
footer .footerContainer .footerContainer-contact p {
  font-size: 1.8rem;
  color: #707070;
  font-family: "Montserrat", sans-serif;
}
footer .footerContainer .footerContainer-socials {
  display: flex;
  gap: 4em;
  align-items: flex-end;
  margin-bottom: 0.8em;
}
footer .footerContainer .footerContainer-socials img {
  height: 30px;
  cursor: pointer;
}
footer .footerMobileContainer {
  display: none;
}

@media (max-width: 1200px) {
  footer {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  footer .logo__mobile {
    display: block;
  }
  footer .footerContainer {
    display: none;
  }
  footer .footerContainer .footerContainer-contact {
    gap: 4em;
  }
  footer .footerContainer .footerContainer-contact p {
    font-size: 1.7rem;
  }
  footer .footerContainer .footerContainer-routers {
    gap: 1.4em;
  }
  footer .footerContainer .footerContainer-routers a {
    font-size: 1.7rem;
  }
  footer .footerContainer .footerContainer-logoRow {
    display: none;
  }
  footer .footerContainer .footerContainer-socials {
    display: none;
  }
  footer .footerMobileContainer {
    width: 100%;
    height: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  footer .footerMobileContainer .betterButton-containerFooter {
    display: flex;
  }
  footer .footerMobileContainer .footer-line {
    margin-top: 3em;
    display: inline-block;
    max-width: 300px;
    width: 80%;
    height: 2px;
    background-color: #707070;
    margin-bottom: 2em;
  }
  footer .footerMobileContainer .footerMobileContainer-socials {
    display: flex;
    gap: 4em;
    align-items: center;
    margin-bottom: 4em;
  }
  footer .footerMobileContainer .footerMobileContainer-socials .yt {
    height: 25px;
  }
  footer .footerMobileContainer p {
    margin-top: 2em;
    font-size: 1.5rem;
    color: #707070;
    font-family: "Montserrat", sans-serif;
  }
}
@media (min-width: 1201px) and (max-width: 1919px) {
  Footer .footerContainer {
    margin-left: 7%;
    width: 86%;
  }
}

