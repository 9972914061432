$whiteColor: #ffffff;
$white2Color: #f0f0f0;
$gray1Color: #E6E6E6;
$gray2Color: #D9D9D9;
$gray3Color: #DDDDDD;
$mainFontColor: #14181D;
$secondFontColor: #707070;
$blueColor: #1C3A66;
$redColor: #E92428;
$bgTextTransparentColor: rgba(0,0,0,0.05)

