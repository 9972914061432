.container {
  overflow: hidden;
}
.container .container-carousel .container-carousel-element {
  position: relative;
  background-color: #E6E6E6;
  width: 10px;
  height: 200px;
  font-size: 1.5rem;
  text-align: left;
  padding: 1.8em;
  margin: -1px;
  font-family: "Montserrat-Regular", sans-serif;
  line-height: 1.6;
}
.container .container-carousel .container-carousel-element .container-carousel-element-smallContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 0;
  margin-bottom: 1.8em;
}
.container .container-carousel .container-carousel-element .container-carousel-element-smallContainer .container-carousel-element-smallContainer__name {
  font-weight: bold;
  color: #1C3A66;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container .container-carousel .container-carousel-element .container-carousel-element-smallContainer .container-carousel-element-smallContainer__name .dot {
  height: 4px;
  width: 4px;
  border-radius: 50%;
  background-color: #1C3A66;
  margin-right: 7px;
}
.container .container-carousel .container-carousel-element .container-carousel-element-smallContainer .container-carousel-element-smallContainer__stars {
  display: flex;
  align-items: center;
  margin-right: 3.5em;
}
.container .container-carousel .odd {
  background-color: #D9D9D9;
}

@media (max-width: 476px) {
  .container .container-carousel .container-carousel-element {
    font-size: 1.4rem;
    height: 250px;
  }
}
@media (max-width: 1088px) and (min-width: 768px) {
  .container .container-carousel .container-carousel-element {
    font-size: 1.6rem;
    height: 270px;
  }
}
@media (max-width: 1600px) and (min-width: 1201px) {
  .container .container-carousel .container-carousel-element {
    font-size: 1.6rem;
    height: 260px;
  }
}
@media (min-width: 2321px) {
  .container .container-carousel .container-carousel-element {
    font-size: 1.8rem;
  }
}

