body {
  position: relative;
  margin: 0;
  padding: 0;
}

h3 {
  font-size: 2.6rem;
  font-family: "Montserrat-SemiBold", sans-serif;
  color: #14181D;
}

h2 {
  font-size: 4rem;
  font-family: "Montserrat-SemiBold", sans-serif;
  color: #14181D;
}

.calculatorSubContainerBg {
  position: relative;
  width: 100%;
  margin-top: -9px;
  background-color: #E6E6E6;
}
.calculatorSubContainerBg .calculatorSubContainer {
  margin-left: 12.5%;
  width: 75%;
  height: 100%;
  padding-top: 10em;
}
.calculatorSubContainerBg .calculatorSubContainer .calculatorSubContainer-elements {
  margin-top: 4em;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}
.calculatorSubContainerBg .calculatorSubContainer .calculatorSubContainer-elements .calculatorSubContainer-elements__text {
  width: 40%;
  font-size: 2rem;
  font-family: "Montserrat-Regular", sans-serif;
}
.calculatorSubContainerBg .calculatorSubContainer .calculatorSubContainer-elements .calculatorSubContainer-elements__text p {
  margin-bottom: 2em;
}
.calculatorSubContainerBg .calculatorSubContainer .calculatorSubContainer-elements .calculatorSubContainer-elements__calc {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
}
.calculatorSubContainerBg .calculatorSubContainer .calculatorSubContainer-elements .calculatorSubContainer-elements__calc .betterButton-container {
  margin-top: 2em;
  display: flex;
}
.calculatorSubContainerBg .calculatorSubContainer .calculatorSubContainer-elements .calculatorSubContainer-elements__calc input[type=number]::-webkit-inner-spin-button,
.calculatorSubContainerBg .calculatorSubContainer .calculatorSubContainer-elements .calculatorSubContainer-elements__calc input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
.calculatorSubContainerBg .calculatorSubContainer .calculatorSubContainer-elements .calculatorSubContainer-elements__calc input[type=number] {
  -moz-appearance: textfield;
}
.calculatorSubContainerBg .calculatorSubContainer .calculatorSubContainer-elements .calculatorSubContainer-elements__calc label {
  color: #707070;
  display: block;
  font-size: 2rem;
  margin: 1em 0;
  font-family: "Montserrat", sans-serif;
}
.calculatorSubContainerBg .calculatorSubContainer .calculatorSubContainer-elements .calculatorSubContainer-elements__calc label ::-webkit-scrollbar {
  display: none;
}
.calculatorSubContainerBg .calculatorSubContainer .calculatorSubContainer-elements .calculatorSubContainer-elements__calc input {
  margin-top: 0.6em;
  height: 50px;
  width: 350px;
  display: block;
  border: none;
  background-color: #D9D9D9;
  -webkit-appearance: none;
  font-size: 1.8rem;
  color: #14181D;
  padding-left: 5px;
  font-family: "Montserrat", sans-serif;
}
.calculatorSubContainerBg .calculatorSubContainer .calculatorSubContainer-elements .calculatorSubContainer-elements__calc input:focus {
  outline: none;
}
.calculatorSubContainerBg .calculatorSubContainer .calculatorSubContainer-elements .calculatorSubContainer-elements__calc select:focus {
  outline: none;
}
.calculatorSubContainerBg .calculatorSubContainer .calculatorSubContainer-elements .calculatorSubContainer-elements__calc select {
  margin-top: 0.6em;
  height: 50px;
  width: 350px;
  display: block;
  border: none;
  background-color: #D9D9D9;
  font-size: 1.8rem;
  color: #14181D;
  padding-left: 3px;
  font-family: "Montserrat", sans-serif;
}
.calculatorSubContainerBg .calculatorSubContainer .calculatorSubContainer-elements .calculatorSubContainer-elements__calc p {
  margin-top: 1.5em;
  font-size: 2.4rem;
  font-family: "Montserrat", sans-serif;
}
.calculatorSubContainerBg .calculatorSubContainer .calculatorSubContainer-elements table {
  margin-top: 1.5em;
  margin-bottom: 7em;
  border-collapse: collapse;
}
.calculatorSubContainerBg .calculatorSubContainer .calculatorSubContainer-elements table thead tr th {
  border: 1px solid #14181D;
  padding: 0.7em 1.2em;
  width: 160px;
}
.calculatorSubContainerBg .calculatorSubContainer .calculatorSubContainer-elements table tbody tr {
  margin: 0;
  padding: 0;
}
.calculatorSubContainerBg .calculatorSubContainer .calculatorSubContainer-elements table tbody tr td {
  text-align: center;
  border: 1px solid #14181D;
  padding: 0.7em 1.2em;
}
.calculatorSubContainerBg .procedureContainer-content__lines__bottom {
  display: flex;
  bottom: 0;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 2rem;
  z-index: 2;
}
.calculatorSubContainerBg .procedureContainer-content__lines__bottom .procedureContainer-content__lines__blue {
  height: 12vh;
  width: 60px;
  background-color: #1C3A66;
  z-index: 3;
}
.calculatorSubContainerBg .procedureContainer-content__lines__bottom .procedureContainer-content__lines__red {
  height: 12vh;
  width: 60px;
  background-color: #E92428;
  z-index: 3;
}

.calculatorDescriptionContainer {
  margin-left: 12.5%;
  margin-top: 10em;
  width: 75%;
  background-color: #f0f0f0;
}
.calculatorDescriptionContainer h3 {
  margin: 1em 0;
  font-family: "Montserrat", sans-serif;
}
.calculatorDescriptionContainer li {
  margin-left: 2em;
  padding: 0.2em;
  color: #14181D;
  font-size: 2rem;
  font-family: "Montserrat-Regular", sans-serif;
}
.calculatorDescriptionContainer .procedureContainer-content__lines__bottom {
  margin-top: 10em;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 2rem;
  z-index: 2;
}
.calculatorDescriptionContainer .procedureContainer-content__lines__bottom .procedureContainer-content__lines__blue {
  height: 5vh;
  width: 60px;
  background-color: #1C3A66;
  z-index: 3;
}
.calculatorDescriptionContainer .procedureContainer-content__lines__bottom .procedureContainer-content__lines__red {
  height: 5vh;
  width: 60px;
  background-color: #E92428;
  z-index: 3;
}

@media (max-width: 476px) {
  .calculatorH2 {
    font-size: 3rem;
    text-align: center;
  }

  .calculatorSubContainerBg .calculatorSubContainer {
    margin-left: 5%;
    width: 90%;
  }
  .calculatorSubContainerBg .calculatorSubContainer .calculatorSubContainer-elements {
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
  }
  .calculatorSubContainerBg .calculatorSubContainer .calculatorSubContainer-elements .calculatorSubContainer-elements__text {
    width: 100%;
    font-size: 1.8rem;
  }
  .calculatorSubContainerBg .calculatorSubContainer .calculatorSubContainer-elements .calculatorSubContainer-elements__text h3 {
    font-size: 2.4rem;
  }
  .calculatorSubContainerBg .calculatorSubContainer .calculatorSubContainer-elements .calculatorSubContainer-elements__text table {
    margin-bottom: 5em;
    width: 100%;
  }
  .calculatorSubContainerBg .calculatorSubContainer .calculatorSubContainer-elements .calculatorSubContainer-elements__calc {
    align-items: center;
    margin-bottom: 1.5em;
    width: 100%;
  }
  .calculatorSubContainerBg .calculatorSubContainer .calculatorSubContainer-elements .calculatorSubContainer-elements__calc label {
    width: 100%;
  }
  .calculatorSubContainerBg .calculatorSubContainer .calculatorSubContainer-elements .calculatorSubContainer-elements__calc input {
    width: 100%;
  }
  .calculatorSubContainerBg .calculatorSubContainer .calculatorSubContainer-elements .calculatorSubContainer-elements__calc select {
    width: 100%;
  }
  .calculatorSubContainerBg .procedureContainer-content__lines__bottom {
    gap: 1rem;
  }
  .calculatorSubContainerBg .procedureContainer-content__lines__bottom .procedureContainer-content__lines__blue {
    height: 100px;
    width: 40px;
  }
  .calculatorSubContainerBg .procedureContainer-content__lines__bottom .procedureContainer-content__lines__red {
    height: 100px;
    width: 40px;
  }

  .calculatorDescriptionContainer {
    margin-left: 5%;
    width: 90%;
    margin-top: 5em;
  }
  .calculatorDescriptionContainer h3 {
    font-size: 2.4rem;
  }
  .calculatorDescriptionContainer li {
    margin-left: 0.5em;
    font-size: 1.8rem;
  }
  .calculatorDescriptionContainer .procedureContainer-content__lines__bottom {
    gap: 1rem;
  }
  .calculatorDescriptionContainer .procedureContainer-content__lines__bottom .procedureContainer-content__lines__blue {
    height: 40px;
    width: 40px;
  }
  .calculatorDescriptionContainer .procedureContainer-content__lines__bottom .procedureContainer-content__lines__red {
    width: 40px;
    height: 40px;
  }
}
@media (max-width: 1200px) and (min-width: 477px) {
  .calculatorH2 {
    font-size: 4rem;
    text-align: center;
  }

  .calculatorSubContainerBg .calculatorSubContainer {
    margin-left: 7%;
    width: 86%;
    padding-top: 3em;
  }
  .calculatorSubContainerBg .calculatorSubContainer .calculatorSubContainer-elements {
    display: flex;
  }
  .calculatorSubContainerBg .calculatorSubContainer .calculatorSubContainer-elements .calculatorSubContainer-elements__text {
    width: 40%;
    font-size: 1.8rem;
  }
  .calculatorSubContainerBg .calculatorSubContainer .calculatorSubContainer-elements .calculatorSubContainer-elements__text h3 {
    font-size: 2.4rem;
  }
  .calculatorSubContainerBg .calculatorSubContainer .calculatorSubContainer-elements .calculatorSubContainer-elements__text table {
    margin-bottom: 5em;
    width: 100%;
  }
  .calculatorSubContainerBg .calculatorSubContainer .calculatorSubContainer-elements .calculatorSubContainer-elements__calc {
    align-items: flex-end;
    margin-bottom: 1.5em;
    width: 50%;
  }
  .calculatorSubContainerBg .calculatorSubContainer .calculatorSubContainer-elements .calculatorSubContainer-elements__calc label {
    width: 100%;
  }
  .calculatorSubContainerBg .calculatorSubContainer .calculatorSubContainer-elements .calculatorSubContainer-elements__calc input {
    width: 100%;
  }
  .calculatorSubContainerBg .calculatorSubContainer .calculatorSubContainer-elements .calculatorSubContainer-elements__calc select {
    width: 100%;
  }
  .calculatorSubContainerBg .procedureContainer-content__lines__bottom {
    gap: 1rem;
  }
  .calculatorSubContainerBg .procedureContainer-content__lines__bottom .procedureContainer-content__lines__blue {
    height: 100px;
    width: 40px;
  }
  .calculatorSubContainerBg .procedureContainer-content__lines__bottom .procedureContainer-content__lines__red {
    height: 100px;
    width: 40px;
  }

  .calculatorDescriptionContainer {
    margin-left: 7%;
    width: 86%;
    margin-top: 5em;
  }
  .calculatorDescriptionContainer h3 {
    font-size: 2.4rem;
  }
  .calculatorDescriptionContainer li {
    margin-left: 0.5em;
    font-size: 1.8rem;
  }
  .calculatorDescriptionContainer .procedureContainer-content__lines__bottom {
    gap: 1rem;
  }
  .calculatorDescriptionContainer .procedureContainer-content__lines__bottom .procedureContainer-content__lines__blue {
    height: 40px;
    width: 40px;
  }
  .calculatorDescriptionContainer .procedureContainer-content__lines__bottom .procedureContainer-content__lines__red {
    width: 40px;
    height: 40px;
  }
}
@media (max-width: 1200px) and (min-width: 477px) and (orientation: portrait) {
  .calculatorH2 {
    font-size: 4rem;
    text-align: center;
  }

  .calculatorSubContainerBg .calculatorSubContainer {
    margin-left: 7%;
    width: 86%;
    padding-top: 10em;
  }
  .calculatorSubContainerBg .calculatorSubContainer .calculatorSubContainer-elements {
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
  }
  .calculatorSubContainerBg .calculatorSubContainer .calculatorSubContainer-elements .calculatorSubContainer-elements__text {
    width: 100%;
    font-size: 1.8rem;
  }
  .calculatorSubContainerBg .calculatorSubContainer .calculatorSubContainer-elements .calculatorSubContainer-elements__text h3 {
    font-size: 2.4rem;
  }
  .calculatorSubContainerBg .calculatorSubContainer .calculatorSubContainer-elements .calculatorSubContainer-elements__text table {
    margin-bottom: 5em;
    width: 100%;
  }
  .calculatorSubContainerBg .calculatorSubContainer .calculatorSubContainer-elements .calculatorSubContainer-elements__calc {
    align-items: center;
    margin-bottom: 1.5em;
    width: 100%;
  }
  .calculatorSubContainerBg .calculatorSubContainer .calculatorSubContainer-elements .calculatorSubContainer-elements__calc label {
    width: 100%;
  }
  .calculatorSubContainerBg .calculatorSubContainer .calculatorSubContainer-elements .calculatorSubContainer-elements__calc input {
    width: 100%;
  }
  .calculatorSubContainerBg .calculatorSubContainer .calculatorSubContainer-elements .calculatorSubContainer-elements__calc select {
    width: 100%;
  }
  .calculatorSubContainerBg .procedureContainer-content__lines__bottom {
    gap: 1rem;
  }
  .calculatorSubContainerBg .procedureContainer-content__lines__bottom .procedureContainer-content__lines__blue {
    height: 100px;
    width: 40px;
  }
  .calculatorSubContainerBg .procedureContainer-content__lines__bottom .procedureContainer-content__lines__red {
    height: 100px;
    width: 40px;
  }

  .calculatorDescriptionContainer {
    margin-left: 7%;
    width: 86%;
    margin-top: 5em;
  }
  .calculatorDescriptionContainer h3 {
    font-size: 2.4rem;
  }
  .calculatorDescriptionContainer li {
    margin-left: 0.5em;
    font-size: 1.8rem;
  }
  .calculatorDescriptionContainer .procedureContainer-content__lines__bottom {
    gap: 1rem;
  }
  .calculatorDescriptionContainer .procedureContainer-content__lines__bottom .procedureContainer-content__lines__blue {
    height: 40px;
    width: 40px;
  }
  .calculatorDescriptionContainer .procedureContainer-content__lines__bottom .procedureContainer-content__lines__red {
    width: 40px;
    height: 40px;
  }
}
@media (min-width: 1201px) and (max-width: 1919px) {
  .calculatorSubContainerBg .calculatorSubContainer {
    margin-left: 7%;
    width: 86%;
  }

  .calculatorDescriptionContainer {
    margin-left: 7%;
    width: 86%;
  }
}

