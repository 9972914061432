@import "colors";

.aboutUsContainer {
  width: 100%;
  //height: 1650px;
  top: -73px;
  background-color: $white2Color;
  position: relative;
  z-index: 3;


  h2 {
    font-size: 7rem;
    text-transform: uppercase;
    font-family: "Manrope-SemiBold", sans-serif;
    color: $bgTextTransparentColor;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .aboutUsContainer-content {
    display: flex;
    justify-content: space-between;
    margin-top: calc(10em + 110px);



    .aboutUsContainer-content__text {
      display: flex;
      flex-direction: column;
      gap: 3rem;
      width: 30%;
      margin-left: 12.5%;
      transition: 0.5s;

      img {
        margin-top: 100px;
        width: 730px;
        margin-left: -5%;
      }

      .title {
        font-size: 4rem;
        font-family: "Montserrat-SemiBold", sans-serif;
      }

      .text {
        font-size: 2rem;
        font-family: "Manrope-Regular", sans-serif;

      }
    }

    .aboutUsContainer-content-carousel {
      width: 35%;
      margin-right: 12.5%;
    }

  }
  .aboutUsContainer-squares {
    display: flex;
    justify-content: space-between;
    margin: 100px 12% 130px;
    flex-wrap: wrap;

    .aboutUsContainer-squares__square:hover {
      .aboutUsContainer-squares-textContainer {
        .aboutUsContainer-squares__mainText {
          color: $redColor;
        }
      }
    }

    .aboutUsContainer-squares__square {
      width: 330px;
      height: 250px;
      background-color: $gray2Color;
      display: flex;
      flex-direction: column;
      margin: 10px;
      z-index: 4;


      .aboutUsContainer-squares-textContainer {

        margin-top: 55px;
        display: flex;
        align-items: center;
        flex-direction: column;

        .aboutUsContainer-squares__mainText  {
          font-size: 7rem;
          font-family: "Montserrat-SemiBold", sans-serif;
          color: $blueColor;
          transition: 0.5s;
        }
        .aboutUsContainer-squares__text {
          display: inline-block;
          text-align: center;
          font-size: 1.8rem;
          font-family: "Montserrat-SemiBold", sans-serif;
          color: $mainFontColor;
          padding: 1em;

        }
      }

    }
  }
  .aboutUsContainer-lines__bottom {
    display: flex;
    position: absolute;
    bottom: 0;
    margin-left: 12.5%;
    height: 300px;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 2rem;
    z-index: 2;

    .aboutUsContainer-lines__blue {
      height: 5vh;
      width: 60px;
      background-color: $blueColor;
      z-index: 3;
    }

    .aboutUsContainer-lines__red {
      height: 5vh;
      width: 60px;
      background-color: $redColor;
      z-index: 3;
    }
  }
}

// MEDIA


//MOBILE DEVICES FROM 0 TO 475
@media (max-width: 767px) {

  .aboutUsContainer {
    //height: 1700px;
    h2 {
      font-size: 2.5rem;
      top: 20px;
    }
    .aboutUsContainer-content {
      margin-top: 75px;
      flex-direction: column;
      .aboutUsContainer-content__text {
        width: 90%;
        margin-left: 5%;
        margin-right: 5%;
        margin-top: 5em;

        .betterButton-container {
          display: flex;
          margin-top: 20px;
          margin-bottom: 15px;

        }

        .title {
          font-size: 2.5rem;
        }
        .text {
          font-size: 1.8rem;

        }
        .blueCar {
          display: none;
        }

      }
      .aboutUsContainer-content-carousel {
        margin-top: 50px;
        width: 90%;
        margin-left: 5%;
      }
    }
    .aboutUsContainer-squares {
      margin-top: 75px;
      margin-left: 5%;
      width: 90%;
      .aboutUsContainer-squares__square {
        width: 43%;
        height: 170px;
        //margin: 0 0 5%;

        .aboutUsContainer-squares-textContainer {
          margin-top: 40px;
          .aboutUsContainer-squares__mainText {
            font-size: 4rem;
          }
          .aboutUsContainer-squares__text {
            font-size: 1.5rem;
          }
        }

      }
    }
    .aboutUsContainer-lines__bottom {
      gap: 1rem;
      margin-left: 5%;

      .aboutUsContainer-lines__blue {
        height: 60px;
        width: 40px;

      }
      .aboutUsContainer-lines__red {
        height: 60px;
        width: 40px;
      }
    }
  }

}

//TABLETS FROM 476 TO 1200
@media (max-width: 1200px) and (min-width: 768px) {

  .aboutUsContainer {


    .aboutUsContainer-content {

      .aboutUsContainer-content__text {
        margin-left: 7%;
        width: 40%;

        p.title {
          margin-top: 2em;
          font-size: 2.5rem ;
        }
        p.text {
          font-size: 1.6rem;
        }
        .blueCar {
          display: none;
        }

      }
      .aboutUsContainer-content-carousel {
        width: 40%;
        margin-right: 7%;
      }
    }
    .aboutUsContainer-squares {
      margin-top: 75px;
      margin-left: 6%;
      width: 88%;
      .aboutUsContainer-squares__square {
        width: 43%;
        height: 170px;
        //margin: 0 0 5%;

        .aboutUsContainer-squares-textContainer {
          margin-top: 40px;
          .aboutUsContainer-squares__mainText {
            font-size: 4rem;
          }
          .aboutUsContainer-squares__text {
            font-size: 1.5rem;
          }
        }

      }
    }
    .aboutUsContainer-lines__bottom {
      gap: 1rem;
      margin-left: 7%;

      .aboutUsContainer-lines__blue {
        height: 60px;
        width: 40px;

      }
      .aboutUsContainer-lines__red {
        height: 60px;
        width: 40px;
      }
    }

  }

}


@media (max-width: 1200px) and (min-width: 768px) and (orientation: portrait){

  .aboutUsContainer {
    h2 {
      font-size: 5.5rem ;
    }

    .aboutUsContainer-content {

      flex-direction: column;
      margin-top: 75px;
      .aboutUsContainer-content__text {
        margin-left: 7%;
        width: 40%;
        .blueCar {
          display: none;
        }

      }
      .aboutUsContainer-content-carousel {
        margin-top: 4em;
        width: 86%;
        margin-left: 7%;
      }
    }
    .aboutUsContainer-squares {
      margin-top: 75px;
      margin-left: 6%;
      width: 88%;
      .aboutUsContainer-squares__square {
        width: 43%;
        height: 170px;
        //margin: 0 0 5%;

        .aboutUsContainer-squares-textContainer {
          margin-top: 40px;
          .aboutUsContainer-squares__mainText {
            font-size: 4rem;
          }
          .aboutUsContainer-squares__text {
            font-size: 1.5rem;
          }
        }

      }
    }
  }


}
//LAPTOPS FROM 1201 TO 1600
@media (max-width: 1600px) and (min-width: 1201px){

  .aboutUsContainer {
    //height: 1450px;

    .aboutUsContainer-content {
      margin-top: 150px;

      .aboutUsContainer-content__text {
        .title {
          font-size: 3.7rem;
        }

        .blueCar {
          width: 600px;
          margin-left: -10%;
        }
      }
    }

    .aboutUsContainer-squares {
      margin-top: 75px;
      margin-left: 12%;
      flex-wrap: nowrap;

      .aboutUsContainer-squares__square {
        width: 265px;
        height: 175px;
        //margin: 0 0 5%;

        .aboutUsContainer-squares-textContainer {
          margin-top: 40px;
          .aboutUsContainer-squares__mainText {
            font-size: 5rem;
          }
          .aboutUsContainer-squares__text {
            font-size: 1.5rem;
          }
        }

      }
    }
  }
}

@media (max-width: 1700px) and (min-width: 1601px) {

  
}

@media (max-width: 1920px) and (min-width: 1701px){

}

@media (max-width: 2100px) and (min-width: 1921px){

}

@media (max-width: 2320px) and (min-width: 2101px){

}

@media (min-width: 2321px) {
  .aboutUsContainer {

    .aboutUsContainer-content {

      .aboutUsContainer-content__text {
        .title {
          font-size: 6rem;
        }
        .text {
          font-size: 2.4rem;
        }

        .blueCar {
          width: 850px;
          margin-left: 5%;
          margin-top: 25px;
        }
      }
    }

    .aboutUsContainer-squares {
      margin-top: 25px;
      margin-left: 12%;
      flex-wrap: nowrap;

      .aboutUsContainer-squares__square {
        width: 465px;
        height: 250px;
        //margin: 0 0 5%;

        .aboutUsContainer-squares-textContainer {
          margin-top: 55px;
          .aboutUsContainer-squares__mainText {
            font-size: 7rem;
          }
          .aboutUsContainer-squares__text {
            font-size: 2rem;
          }
        }

      }
    }
  }
  
}
@media (min-width: 1201px) and (max-width: 1919px) {
  .aboutUsContainer {

    .aboutUsContainer-content {

      .aboutUsContainer-content__text {
        margin-left: 7%;
      }

      .aboutUsContainer-content-carousel {
        margin-right: 7%;
      }
    }
    .aboutUsContainer-squares {
      margin-left: 7%;
      margin-right: 7%;
    }

    .aboutUsContainer-lines__bottom {
      margin-left: 7%;
    }
  }
}
