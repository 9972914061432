@import "colors";

footer {
  width: 100%;
  background-color: $gray1Color;
  position: relative;
  z-index: 0;


  .logo__mobile {
    height: 75px;
    width: auto;
    display: none;
    //margin-left: 5%;
    margin-left: 50%;
    transform: translateX(-50%);
    margin-top: 3em;

  }

  .footer-line {
    display: none;

  }

 .footerContainer {
   margin-left: 12.5%;
   width: 75%;
   height: 100%;
   display: flex;
   justify-content: space-between;
   padding: 2em 0;

   .footerContainer-logoRow {
     display: flex;
     justify-content: center;
     flex-direction: column;
     align-items: flex-start;
     gap: 3em;

     .logo {
       height: 100px;
       width: auto;
       margin-left: 30px;
       margin-top: 3em;

     }

     p {
       color: $secondFontColor;
       font-family: "Montserrat", sans-serif;
       font-size: 1.8rem;
     }


     .betterButton-container {
       display: flex;
       justify-content: space-between;
     }
   }
   .footerContainer-routers {
     font-size: 2rem;
     justify-content: center;
     gap: 0.8em;
     display: flex;
     flex-direction: column;

     a {

       text-decoration: none;
       color: $secondFontColor;
       font-family: "Montserrat", sans-serif;
     }
   }

   .footerContainer-contact {
     justify-content: center;
     display: flex;
     flex-direction: column;
     gap: 2em;

     p {
       font-size: 1.8rem;
       color: $secondFontColor;
       font-family: "Montserrat", sans-serif;
     }
   }

   .footerContainer-socials {
     display: flex;
     gap: 4em;
     align-items: flex-end;
     margin-bottom: 0.8em;

     img {
       height: 30px;
       cursor: pointer;
     }
   }


 }
  .footerMobileContainer {
    display: none;

  }
}

// MEDIA

//MOBILE DEVICES FROM 0 TO 475
@media (max-width: 1200px) {

  footer {
    padding-top: 20px;
    padding-bottom: 20px;



    .logo__mobile {
      display: block;
    }
    .footerContainer {
      display: none;


      .footerContainer-contact {
        gap: 4em;

        p {
          font-size: 1.7rem;
        }
      }

      .footerContainer-routers {
        gap: 1.4em;

        a {
          font-size: 1.7rem;
        }
      }

      .footerContainer-logoRow {
        display: none;

        .betterButton-container {
        }
      }

      .footerContainer-socials {
        display: none;
      }
    }



    .footerMobileContainer {
      width: 100%;
      height: 40%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      .betterButton-containerFooter {
        display: flex;
      }

      .footer-line {
        margin-top: 3em;
        display: inline-block;
        max-width: 300px;
        width: 80%;
        height: 2px;
        background-color: $secondFontColor;
        margin-bottom: 2em;
      }
      .footerMobileContainer-socials {
        display: flex;
        gap: 4em;
        align-items: center;
        margin-bottom: 4em;

        .yt {
         height: 25px;
        }
      }

      p {
        margin-top: 2em;
        font-size: 1.5rem;
        color: $secondFontColor;
        font-family: "Montserrat", sans-serif;
      }
    }
  }
}

@media (min-width: 1201px) and (max-width: 1919px) {

  Footer {
    .footerContainer {
      margin-left: 7%;
      width: 86%;
    }
  }
}
