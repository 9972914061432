.customIcon {
  width: 20px;
  height: 20px;
  transition: transform 0.3s ease-in-out;
  rotate: 180deg;
}

.rotate {
  transform: rotate(180deg);
  transition: 0.5s;
}

.rotateBackwards {
  transform: rotate(0deg);
  transition: 0.5s;
}

.container {
  width: 100%;
  margin: auto;
}

.accordion {
  width: 100%;
  margin: 3em auto;
}

.accordionHead {
  background-color: #D9D9D9;
  padding: 1em;
  font-size: 2rem;
  font-family: "Montserrat-SemiBold", sans-serif;
}
.accordionHead p {
  margin-right: 1em;
}
.accordionHead .container {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.accordionContent {
  background-color: #D9D9D9;
  display: flex;
  overflow: hidden;
  max-height: 0;
  transition: 0.5s;
}
.accordionContent p {
  padding: 1em 4em 2em 1em;
  font-size: 2rem;
  font-family: "Montserrat-Regular", sans-serif;
}

.accordionContent.show {
  max-height: 330px;
  transition: 1s;
}

@media (max-width: 476px) {
  .accordionHead p {
    font-size: 1.8rem;
  }

  .accordionContent p {
    padding: 1em 1em 2em 1em;
    font-size: 1.8rem;
  }
}

