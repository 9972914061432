@import "colors";


.betterButton-container {
  display: flex;

  .betterButton-container__blue {
    background-color: $blueColor;
    height: 100%;
    width: 15px;
  }
  .betterButton-container__red {
    background-color: $redColor;
    height: 100%;
    width: 15px;
    transition: 0.3s;
  }

  .betterButton-container-button {
    background-color: $gray2Color;
    border: none;
    width: 10em;
    font-size: 2rem;
    height: 2.5em;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    position: relative;
    z-index: 0;

    .betterButton-text {
      transition: 0.3s;
      z-index: 1;
      font-size: 1.8rem;
      color: $mainFontColor;
      font-family: "Montserrat", sans-serif;
    };

  }
}
.betterButton-container-button,
.betterButton-container-button::after,
.betterButton-container-button::before {
  transition: transform 0.3s ease-in-out;
}

.betterButton-container-button::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-color: $white2Color;
  transform: scaleX(0);
  transform-origin: right;
}

.betterButton-container-button:hover::after{
  transform: scaleX(100%);
  transform-origin: left;

}



