@import "colors";

body {
  position: relative;
  margin: 0;
  padding: 0;
}

h3 {
  font-size: 2.6rem;
  font-family: "Montserrat-SemiBold", sans-serif;
  color: $mainFontColor;
}


.aboutUsSubContainerBg {
  position: relative;
  width: 100%;
  margin-top: -9px;
  background-color: $gray1Color;

  .aboutUsSubContainer {
    margin-left: 12.5%;
    width: 75%;
    height: 100%;
    padding-top: 10em;

    .aboutUsSubContainer-elements {
      margin-top: 4em;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;

      .aboutUsContainer-elements__text {
        width: 40%;
        font-size: 2rem;
        font-family: "Montserrat-Regular", sans-serif;

        p {
          margin-bottom: 2em;
        }

        .lastP {
          margin-bottom: 7em;
        }

        .procedureContainer-content__lines__bottom {
          display: flex;
          bottom: 0;
          align-items: flex-end;
          justify-content: flex-start;
          gap: 2rem;
          z-index: 2;

          .procedureContainer-content__lines__blue {
            height: 12vh;
            width: 60px;
            background-color: $blueColor;
            z-index: 3;
          }

          .procedureContainer-content__lines__red {
            height: 12vh;
            width: 60px;
            background-color: $redColor;
            z-index: 3;
          }
        }
      }

      img {
        margin-top: 7em;
        //width: 700px;
        width: 40vw;
      }
    }
  }
}

.aboutUsDescriptionContainer {
  background-color: $white2Color;
  margin-top: 10em;
  margin-left: 12.5%;
  width: 75%;

  .aboutUsDescriptionContainer-reviews {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    .container-carousel-element {
      position: relative;
      background-color: $gray1Color;
      width: 50%;
      height: 200px;
      font-size: 1.5rem;
      text-align: left;
      padding: 1.8em;
      font-family: "Montserrat-Regular", sans-serif;
      line-height: 1.6;

      p {

      }

      .container-carousel-element-smallContainer {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        bottom: 0;
        margin-bottom: 1.8em;

        .container-carousel-element-smallContainer__name {
          font-weight: bold;
          color: $blueColor;
          display: flex;
          justify-content: center;
          align-items: center;

          .dot {
            height: 4px;
            width: 4px;
            border-radius: 50%;
            background-color: $blueColor;
            margin-right: 7px;
          }

          .authorName {

          }
        }

        .container-carousel-element-smallContainer__stars {
          display: flex;
          align-items: center;
          margin-right: 3.5em;
        }
      }
    }
  }

  .odd {
    background-color: $gray2Color !important;
  }

  .aboutUsContainer-squares {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 10em 0;

    .aboutUsContainer-squares__square:hover {
      .aboutUsContainer-squares-textContainer {
        .aboutUsContainer-squares__mainText {
          color: $redColor;
        }
      }
    }

    .aboutUsContainer-squares__square {
      width: 330px;
      height: 250px;
      background-color: $gray2Color;
      display: flex;
      flex-direction: column;
      z-index: 4;


      .aboutUsContainer-squares-textContainer {

        margin-top: 55px;
        display: flex;
        align-items: center;
        flex-direction: column;

        .aboutUsContainer-squares__mainText {
          font-size: 7rem;
          font-family: "Montserrat-SemiBold", sans-serif;
          color: $blueColor;
          transition: 0.5s;
        }

        .aboutUsContainer-squares__text {
          display: inline-block;
          text-align: center;
          font-size: 1.8rem;
          font-family: "Montserrat-SemiBold", sans-serif;
          color: $mainFontColor;
          padding: 1em;

        }
      }

    }
  }

  .procedureContainer-content__lines__bottom {
    margin-top: calc(10em - 3.3%);
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 2rem;
    z-index: 2;

    .procedureContainer-content__lines__blue {
      height: 5vh;
      width: 60px;
      background-color: $blueColor;
      z-index: 3;
    }

    .procedureContainer-content__lines__red {
      height: 5vh;
      width: 60px;
      background-color: $redColor;
      z-index: 3;
    }
  }
}

@media (max-width: 476px) {

  .aboutUsH2 {
    font-size: 3rem;
    text-align: center;
  }

  .aboutUsSubContainerBg {

    .aboutUsSubContainer {
      margin-left: 5%;
      width: 90%;

      .aboutUsSubContainer-elements {

        .aboutUsContainer-elements__text {
          width: 100%;
          font-size: 1.8rem;


          .procedureContainer-content__lines__bottom {
            gap: 1rem;

            .procedureContainer-content__lines__blue {
              height: 100px;
              width: 40px;
            }

            .procedureContainer-content__lines__red {
              height: 100px;
              width: 40px;
            }
          }
        }

        img {
          display: none;
        }
      }
    }
  }

  .aboutUsDescriptionContainer {
    margin-top: 5em;
    margin-left: 5%;
    width: 90%;

    .aboutUsDescriptionContainer-reviews {
      flex-direction: column;

      .container-carousel-element-smallContainer {
        width: 100%;
      }

      .container-carousel-element {
        font-size: 1.4rem;
        height: 250px;
        width: 100%;

        .container-carousel-element-smallContainer {
          display: flex;
          position: absolute;
          justify-content: space-between;
          width: 86%;

          .container-carousel-element-smallContainer__stars {
            margin: 0;
          }
        }
      }
    }


    .second {
      display: none;
    }

    .forth {
      display: none;
    }

    //.odd {
    //  display: none;
    //}

    .aboutUsContainer-squares {
      justify-content: space-around;

      .aboutUsContainer-squares__square {
        width: 43%;
        height: 170px;
        margin: 10px;

        .aboutUsContainer-squares-textContainer {
          margin-top: 40px;

          .aboutUsContainer-squares__mainText {
            font-size: 4rem;
          }

          .aboutUsContainer-squares__text {
            font-size: 1.5rem;
          }
        }
      }
    }

    .procedureContainer-content__lines__bottom {
      gap: 1rem;

      .procedureContainer-content__lines__blue {
        height: 40px;
        width: 40px;
      }

      .procedureContainer-content__lines__red {
        height: 40px;
        width: 40px;
      }
    }
  }
}


@media (max-width: 1200px) and (min-width: 477px) {
  .aboutUsH2 {
    font-size: 4rem;
    text-align: center;
  }

  .aboutUsSubContainerBg {

    .aboutUsSubContainer {
      margin-left: 7%;
      width: 86%;
      padding-top: 3em;

      .aboutUsSubContainer-elements {

        .aboutUsContainer-elements__text {
          width: 40%;
          font-size: 1.8rem;


          .procedureContainer-content__lines__bottom {
            gap: 1rem;

            .procedureContainer-content__lines__blue {
              height: 100px;
              width: 40px;
            }

            .procedureContainer-content__lines__red {
              height: 100px;
              width: 40px;
            }
          }
        }

        img {
          margin-top: 12em;
          width: 45vw;
        }
      }
    }
  }

  .aboutUsDescriptionContainer {
    margin-top: 5em;
    margin-left: 7%;
    width: 86%;

    .aboutUsDescriptionContainer-reviews {
      flex-direction: column;

      .container-carousel-element-smallContainer {
        width: 100%;
      }

      .container-carousel-element {
        font-size: 1.4rem;
        height: 250px;
        width: 100%;

        .container-carousel-element-smallContainer {
          display: flex;
          position: absolute;
          justify-content: space-between;
          width: 86%;

          .container-carousel-element-smallContainer__stars {
            margin: 0;
          }
        }
      }
    }


    .second {
      display: none;
    }

    .forth {
      display: none;
    }

    .aboutUsContainer-squares {
      justify-content: space-around;

      .aboutUsContainer-squares__square {
        width: 43%;
        height: 170px;
        margin: 10px;

        .aboutUsContainer-squares-textContainer {
          margin-top: 40px;

          .aboutUsContainer-squares__mainText {
            font-size: 4rem;
          }

          .aboutUsContainer-squares__text {
            font-size: 1.5rem;
          }
        }
      }
    }
    .procedureContainer-content__lines__bottom {
      gap: 1rem;

      .procedureContainer-content__lines__blue {
        height: 40px;
        width: 40px;
      }

      .procedureContainer-content__lines__red {
        height: 40px;
        width: 40px;
      }
    }
  }
}

@media (max-width: 1200px) and (min-width: 477px) and (orientation: portrait) {
  .aboutUsSubContainerBg {

    .aboutUsSubContainer {
      margin-left: 7%;
      width: 86%;
      padding-top: 10em;

      .aboutUsSubContainer-elements {

        .aboutUsContainer-elements__text {
          width: 100%;
          font-size: 1.8rem;


          .procedureContainer-content__lines__bottom {
            gap: 1rem;

            .procedureContainer-content__lines__blue {
              height: 100px;
              width: 40px;
            }

            .procedureContainer-content__lines__red {
              height: 100px;
              width: 40px;
            }
          }
        }

        img {
          display: none;
        }
      }
    }
  }

  .aboutUsDescriptionContainer {
    margin-top: 5em;
    margin-left: 7%;
    width: 86%;

    .aboutUsDescriptionContainer-reviews {
      flex-direction: column;

      .container-carousel-element-smallContainer {
        width: 100%;
      }

      .container-carousel-element {
        font-size: 1.4rem;
        height: 250px;
        width: 100%;

        .container-carousel-element-smallContainer {
          display: flex;
          position: absolute;
          justify-content: space-between;
          width: 86%;

          .container-carousel-element-smallContainer__stars {
            margin: 0;
          }
        }
      }
    }


    .second {
      display: none;
    }

    .forth {
      display: none;
    }

    .aboutUsContainer-squares {
      justify-content: space-around;

      .aboutUsContainer-squares__square {
        width: 43%;
        height: 170px;
        margin: 10px;

        .aboutUsContainer-squares-textContainer {
          margin-top: 40px;

          .aboutUsContainer-squares__mainText {
            font-size: 4rem;
          }

          .aboutUsContainer-squares__text {
            font-size: 1.5rem;
          }
        }
      }
    }
  }
}

@media (min-width: 1201px) and  (max-width: 1919px) {
  .aboutUsSubContainerBg {

    .aboutUsSubContainer {
      margin-left: 7%;
      width: 86%;
    }
  }
  .aboutUsDescriptionContainer {
    margin-top: 5em;
    margin-left: 7%;
    width: 86%;
    .aboutUsContainer-squares {
      justify-content: space-around;

      .aboutUsContainer-squares__square {
        width: 45%;
        height: 170px;
        margin: 10px;

        .aboutUsContainer-squares-textContainer {
          margin-top: 40px;

          .aboutUsContainer-squares__mainText {
            font-size: 4rem;
          }

          .aboutUsContainer-squares__text {
            font-size: 1.5rem;
          }
        }
      }
    }
  }
}

@media (min-width: 1921px) {

  .aboutUsSubContainerBg {

    .aboutUsSubContainer {

      .aboutUsSubContainer-elements {



        img {
          margin-top: 0;
        }
      }
    }
  }
}


