*, *::after, *::before {
  margin: 0;
  box-sizing: border-box;
}

nav {
  top: 0;
  left: 0;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 70px;
  width: 100%;
  background-color: #ffffff;
  padding: 0;
  z-index: 500;
}
nav .mobileLang {
  display: none;
}
nav .nav-Container {
  width: 100%;
  margin-left: 12.5%;
  margin-right: 12.5%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
nav .nav-Container li {
  list-style: none;
  position: relative;
  padding: 0.7em 0;
}
nav .nav-Container .nav-menu {
  padding: 0;
  display: flex;
  align-items: center;
  gap: 3rem;
}
nav .nav-Container .nav-menu .nav-linkContainer {
  display: flex;
  align-items: center;
  gap: 3rem;
}
nav .nav-Container .nav-menu .nav-divider {
  background-color: #14181D;
  height: 2.5rem;
  width: 1px;
}
nav .nav-Container .nav-menu img {
  height: 4rem;
  width: auto;
  z-index: 5;
}
nav .nav-Container .nav-menu a {
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 1.6rem;
  color: #14181D;
  text-decoration: none;
  z-index: 1;
}
nav .nav-Container .nav-menu .nav-link,
nav .nav-Container .nav-menu .nav-link::after,
nav .nav-Container .nav-menu .nav-link::before {
  transition: 0.3s;
}
nav .nav-Container .nav-menu .nav-link {
  z-index: 0;
}
nav .nav-Container .nav-menu .nav-link::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: -10%;
  height: 100%;
  border-radius: 3px;
  width: 120%;
  background-color: #E6E6E6;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.3s;
  z-index: -1;
}
nav .nav-Container .nav-menu .nav-link:hover::after {
  transform: scaleX(1);
  transform-origin: left;
}
nav .nav-Container .nav-menu .nav-link:hover {
  color: black;
}
nav .nav-Container .nav-langSetting {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
nav .nav-Container .nav-langSetting button {
  color: #14181D;
  border: none;
  background-color: transparent;
  font-size: 1.6rem;
  font-family: "Montserrat-Medium", sans-serif;
  cursor: pointer;
}
nav .nav-Container .nav-langSetting img {
  padding: 0.5em;
}
nav .nav-background__mobile {
  position: fixed;
  background-color: #f0f0f0;
  top: 0;
  left: 0;
  width: 100%;
  height: 70px;
  z-index: 2;
  display: none;
}
nav .expandAnimation {
  width: 100% !important;
}
nav .nav-hamburger {
  display: none;
  justify-content: space-around;
  align-items: flex-end;
  flex-direction: column;
  height: 5rem;
  width: 6rem;
  cursor: pointer;
  padding: 10px;
  z-index: 1;
}
nav .nav-hamburger .line {
  background-color: #14181D;
  height: 2px;
  transition: width 0.5s ease-in-out;
}
nav .nav-hamburger .line1 {
  width: 100%;
}
nav .nav-hamburger .line2 {
  width: 65%;
}
nav .nav-hamburger .line3 {
  width: 40%;
}

@media (max-width: 900px) {
  nav {
    display: flex;
    width: 100%;
  }
  nav .mobileLang {
    display: flex;
  }
  nav .mobileLang button {
    display: none;
  }
  nav .nav-Container .nav-background__mobile {
    display: block;
    z-index: 0;
  }
  nav .nav-Container img .nav-logo {
    position: fixed;
    z-index: 3;
    padding: 0;
    margin: 0;
  }
  nav .nav-Container .nav-divider {
    display: none;
  }
  nav .nav-Container .nav-linkContainer {
    position: fixed;
    top: 70px;
    right: 0;
    width: 100%;
    height: 100vh;
    background-color: #f0f0f0;
    flex-direction: column;
    padding-top: 5em;
    transform: translateY(-100%);
    transition: transform 0.5s ease-in-out;
    align-items: center;
    overflow: hidden;
    z-index: -1;
  }
  nav .nav-Container .nav-linkContainer li:last-child {
    margin-bottom: 70px;
  }
  nav .nav-Container .nav-linkContainer li a {
    z-index: 0;
    font-size: 2rem;
  }
  nav .nav-Container .nav-mobileActive {
    overflow: scroll;
    transform: translateY(0);
  }
  nav .nav-Container .nav-langSetting {
    display: none;
  }
  nav .nav-Container .nav-langSetting button {
    display: none;
  }
  nav .nav-Container .nav-langSetting img {
    display: none;
  }
  nav .nav-Container .nav-hamburger {
    display: flex;
    z-index: 5;
  }
}
@media (max-width: 476px) {
  nav .nav-Container {
    margin-left: 5%;
    margin-right: 5%;
  }
}
@media (min-width: 477px) and (max-width: 1919px) {
  nav .nav-Container {
    margin-left: 7%;
    margin-right: 7%;
  }
}

