@import "colors";

body {
  position: relative;
  margin: 0;
  padding: 0;
}

h2 {
  font-size: 4rem;
  font-family: "Montserrat-SemiBold", sans-serif;
  color: $mainFontColor;
}

.fade-in-out {
  animation-name: fade-in-out;
  animation-duration: 0.7s;
}



.contactContainerBg {
  position: relative;
  width: 100%;
  margin-top: -9px;
  background-color: $gray1Color;


  .contactContainer {
    margin-left: 12.5%;
    width: 75%;
    height: 100%;
    padding-top: 10em;

    .contactContainer-elements {
      margin-top: 4em;
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;

      .contactContainer-elements-cities {
        width: 54%;
        flex-wrap: wrap;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .contactContainer-elements-cities__city {

          cursor: pointer;
          margin: 2em 4em 2em 0;
          display: flex;
          flex-wrap: wrap;

          .mobileMap {
            display: none;
          }

          .contactContainer-elements-cities__city__info.active {
            background-color: $gray2Color;
            transition: 0.5s;
          }
          .contactContainer-elements-cities__city__info {
            width: 320px;
            height: 210px;
            padding: 1em 4em 1em 1em;
            .cityName {
              padding-bottom: 1em;
              font-size: 2rem;
              font-family: "Montserrat-SemiBold", sans-serif;
              color: $mainFontColor;
            }

            p {
              padding-bottom: 0.3em;
              font-family: "Montserrat-Regular", sans-serif;
              color: $mainFontColor;
              font-size: 2rem;
            }
          }
          .contactContainer-elements-cities__city__lines {
            display: flex;
            opacity: 0;

            transition: 0.5s;
            .cityLine-blue {
              width: 10px;
              height: 100%;
              background-color: $redColor;
            }
            .cityLine-red {
              width: 10px;
              height: 100%;
              background-color: $blueColor;

            };
          }
          .contactContainer-elements-cities__city__lines.activeLine {
            opacity: 1;
          }
        }
      }

      img {
        width: 450px;
        height: auto;
      }
    }
    .procedureContainer-content__lines__bottom {
      display: flex;
      margin-top: 7em;
      bottom: 0;
      align-items: flex-end;
      justify-content: flex-start;
      gap: 2rem;
      z-index: 2;

      .procedureContainer-content__lines__blue {
        height: 12vh;
        width: 60px;
        background-color: $blueColor;
        z-index: 3;
      }

      .procedureContainer-content__lines__red {
        height: 12vh;
        width: 60px;
        background-color: $redColor;
        z-index: 3;
      }
    }
  }
}

.emailContainer {
  margin-left: 12.5%;
  margin-top: 10em;
  width: 75%;
  background-color: $white2Color;

  h3 {
    margin: 1em 0 2em;
    font-family: "Montserrat", sans-serif;
  }

  form {
    display: flex;
    justify-content: space-between;

  }

  label {
    color: $secondFontColor;
    display: block;
    font-size: 2rem;
    margin: 1em 0;
    font-family: "Montserrat", sans-serif;

    ::-webkit-scrollbar {
      display: none;
    }


  }

  textarea {
    resize: none;
    margin-top: 0.6em;
    height: 360px;
    width: 600px;
    display: block;
    border: none;
    background-color: $gray2Color;
    -webkit-appearance: none;
    font-size: 1.8rem;
    color: $mainFontColor;
    padding-left: 5px;
    font-family: "Montserrat", sans-serif;
  }
  input {
    margin-top: 0.6em;
    height: 50px;
    width: 350px;
    display: block;
    border: none;
    background-color: $gray2Color;
    -webkit-appearance: none;
    font-size: 1.8rem;
    color: $mainFontColor;
    padding-left: 5px;
    font-family: "Montserrat", sans-serif;
  }

  textarea:focus {
    outline: none;
  }
  input:focus {
    outline: none;
  }

  select:focus {
    outline: none;

  }

  .buttonContainer {
    display: flex;
  }
  .procedureContainer-content__lines__bottom {
    margin-top: 10em;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 2rem;
    z-index: 2;

    .procedureContainer-content__lines__blue {
      height: 5vh;
      width: 60px;
      background-color: $blueColor;
      z-index: 3;
    }

    .procedureContainer-content__lines__red {
      height: 5vh;
      width: 60px;
      background-color: $redColor;
      z-index: 3;
    }
  }
}

@media (max-width: 476px) {
  .contactH2 {
    font-size: 3em;
    text-align: center;
  }

  .contactContainerBg {
    .contactContainer {
      margin-left: 5%;
      width: 90%;

      .contactContainer-elements {
        width: 100%;
        justify-content: flex-start;

        .bigMap {
          display: none;
        }


        .contactContainer-elements-cities {
          width: 100%;

          .contactContainer-elements-cities__city {
            margin: 0;
            padding: 0;
            display: flex;

            .contactContainer-elements-cities__city__info.active {
              background-color: $gray1Color;
            }
            .contactContainer-elements-cities__city__info {
              padding: 1em;
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;

              .contactContainer-elements-cities__city__lines {
              }

              .mobileMap {
                height: 110px;
                width: 110px;
                display: block;
              }
              p {
                font-size: 1.8rem;
              }
            }


          }
        }
      }
      .procedureContainer-content__lines__bottom {
        gap: 1rem;

        .procedureContainer-content__lines__blue {
          height: 100px;
          width: 40px;
        }
        .procedureContainer-content__lines__red {
          height: 100px;
          width: 40px;
        }
      }
    }
  }

  .emailContainer {
    margin-top: 5em;
    margin-left: 5%;
    width: 90%;

    form {
      display: flex;
      flex-direction: column;
      width: 100%;

    }

    label {
      font-size: 1.8rem;
      width: 100%;
    }

    input {
      width: 100%;
    }

    textarea {
      width: 100%;
    }

    .buttonContainer {
      margin-top: 3em;
      justify-content: center;
    }

    .procedureContainer-content__lines__bottom {
      gap: 1rem;

      .procedureContainer-content__lines__blue {
        height: 40px;
        width: 40px;
      }
      .procedureContainer-content__lines__red {
        height: 40px;
        width: 40px;
      }
    }
  }
}
@media (max-width: 1200px) and (min-width: 477px) {
  .contactH2 {
    font-size: 4em;
    text-align: center;
  }

  .contactContainerBg {
    .contactContainer {
      margin-left: 7%;
      width: 86%;
      padding-top: 3em;

      .contactContainer-elements {
        width: 100%;
        justify-content: start;

        .bigMap {
          display: none;
        }


        .contactContainer-elements-cities {
          width: 100%;
          display: flex;
          justify-content: space-between;

          .contactContainer-elements-cities__city {
            margin: 0;
            width: 45%;
            padding: 0;
            display: flex;

            .contactContainer-elements-cities__city__info.active {
              background-color: $gray1Color;
            }
            .contactContainer-elements-cities__city__info {
              padding: 1em;
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;

              .contactContainer-elements-cities__city__lines {
              }

              .mobileMap {
                height: 110px;
                width: 110px;
                display: block;
              }
              p {
                font-size: 1.8rem;
              }
            }


          }
        }
      }
      .procedureContainer-content__lines__bottom {
        gap: 1rem;

        .procedureContainer-content__lines__blue {
          height: 100px;
          width: 40px;
        }
        .procedureContainer-content__lines__red {
          height: 100px;
          width: 40px;
        }
      }
    }
  }

  .emailContainer {
    margin-top: 5em;
    margin-left: 7%;
    width: 86%;

    form {
      display: flex;
      flex-direction: column;
      width: 100%;

    }

    label {
      font-size: 1.8rem;
      width: 100%;
    }

    input {
      width: 100%;
    }

    textarea {
      width: 100%;
    }

    .buttonContainer {
      margin-top: 3em;
      justify-content: flex-end;
    }

    .procedureContainer-content__lines__bottom {
      gap: 1rem;

      .procedureContainer-content__lines__blue {
        height: 40px;
        width: 40px;
      }
      .procedureContainer-content__lines__red {
        height: 40px;
        width: 40px;
      }
    }
  }
}
@media (max-width: 1200px) and (min-width: 477px) and (orientation: portrait){
  .contactContainerBg {
    .contactContainer {
      margin-left: 7%;
      width: 86%;
      padding-top: 10em;

      .contactContainer-elements {
        width: 100%;
        justify-content: start;

        .bigMap {
          display: none;
        }


        .contactContainer-elements-cities {
          width: 100%;
          display: flex;
          justify-content: center;

          .contactContainer-elements-cities__city {
            margin: 0;
            width: 100%;
            padding: 0;
            display: flex;

            .contactContainer-elements-cities__city__info.active {
              background-color: $gray1Color;
            }

            .contactContainer-elements-cities__city__info {
              padding: 1em;
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;

              .contactContainer-elements-cities__city__lines {
              }

              .mobileMap {
                height: 110px;
                width: 110px;
                display: block;
              }


            }


          }
        }
      }
    }
  }

  .emailContainer {
    margin-top: 5em;
    margin-left: 7%;
    width: 86%;
  }
}

@media (min-width: 1201px) and (max-width: 1919px){
  .contactContainerBg {
    .contactContainer {
      margin-left: 7%;
      width: 86%;

      .contactContainer-elements {

        .contactContainer-elements-cities {
          width: 65%;
        }
        .bigMap {
        }
      }
    }
  }

  .emailContainer {
    margin-left: 7%;
    width: 86%;
  }

}
