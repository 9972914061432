@import "colors";

body {
  position: relative;
  margin: 0;
  padding: 0;
}

h2 {
  font-size: 4rem;
  font-family: "Montserrat-SemiBold", sans-serif;
  color: $mainFontColor;
}

.realizationsContainerBg {
  position: relative;
  width: 100%;
  margin-top: -9px;
  background-color: $white2Color;

  .realizationsContainer {
    margin-left: 12.5%;
    width: 75%;
    height: 100%;
    padding-top: 10em;

    .realizationsContainer-elements {
      margin-top: 4em;
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;

      .zoom-wrapper {
        width: 31%;
        height: 280px;
        object-position: bottom;
        margin-bottom: 3.3%;
        overflow: hidden;
        transition: transform 1.8s;

        .scale-container:hover {
          .realizationsContainer-element {
            transition: transform 0.5s;
            transform: scale(1.2);
          }
        }
        .realizationsContainer-element {
          object-fit: cover;
          height: 100%;
          width: 100%;
        }
      }
      //.realizationsContainer-element:hover {
      //  transform: scale(1.2);
      //}
    }
    .procedureContainer-content__lines__bottom {
      margin-top: calc(10em - 3.3%);
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      gap: 2rem;
      z-index: 2;

      .procedureContainer-content__lines__blue {
        height: 5vh;
        width: 60px;
        background-color: $blueColor;
        z-index: 3;
      }

      .procedureContainer-content__lines__red {
        height: 5vh;
        width: 60px;
        background-color: $redColor;
        z-index: 3;
      }
    }
  }
}

@media (max-width: 476px) {
  .realizationsH2 {
    font-size: 3rem;
    text-align: center;
  }
  .realizationsContainerBg {

    .realizationsContainer {
      margin-left: 5%;
      width: 90%;

      .realizationsContainer-elements {

        .zoom-wrapper {
          width: 100%;
          height: 280px;
          object-position: bottom;
          margin-bottom: 5%;
          overflow: hidden;


          .realizationsContainer-element {
            object-fit: cover;
            //transition: transform 0.8s;
            height: 100%;
            width: 100%;
          }
        }
      }

      .procedureContainer-content__lines__bottom {
        margin-top: 5em;
        gap: 1rem;

        .procedureContainer-content__lines__red {
          width: 40px;
          height: 40px;
        }

        .procedureContainer-content__lines__blue {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
}
@media (max-width: 1200px) and (min-width: 477px) {
  .realizationsH2 {
    font-size: 4rem;
    text-align: center;
  }
  .realizationsContainerBg {

    .realizationsContainer {
      margin-left: 7%;
      width: 86%;
      padding-top: 3em;

      .realizationsContainer-elements {

        .lastCarImg {
          display: none;
        }
        .zoom-wrapper {
          width: 48%;
          height: 280px;
          object-position: bottom;
          margin-bottom: 3.3%;
          overflow: hidden;



          .realizationsContainer-element {
            object-fit: cover;
            //transition: transform 0.8s;
            height: 100%;
            width: 100%;
          }
        }
      }

      .procedureContainer-content__lines__bottom {
        margin-top: 5em;
        gap: 1rem;

        .procedureContainer-content__lines__red {
          width: 40px;
          height: 40px;
        }

        .procedureContainer-content__lines__blue {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
}

@media (max-width: 1200px) and (min-width: 477px) and (orientation: portrait){
  .realizationsContainerBg {

    .realizationsContainer {
      margin-left: 7%;
      width: 86%;
      padding-top: 10em;
    }
  }
}

@media (min-width: 1921px) {
  .realizationsContainerBg {

    .realizationsContainer {

      .realizationsContainer-elements {
        .zoom-wrapper {
          height: 380px;
          object-position: bottom;
          margin-bottom: 3.3%;
          overflow: hidden;
        }
      }
    }
  }
}

@media (min-width: 1201px) and (max-width: 1919px) {
  .realizationsContainerBg {

    .realizationsContainer {
      margin-left: 7%;
      width: 86%;
    }
  }
}