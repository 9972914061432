@import "colors";

*, *::after, *::before {
  margin: 0;
  box-sizing: border-box;
}

nav {
  top: 0;
  left: 0;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 70px;
  width: 100%;
  background-color: $whiteColor;
  padding: 0;
  z-index: 500;

  .mobileLang {
    display: none;
  }

  .nav-Container {
    width: 100%;
    margin-left: 12.5%;
    margin-right: 12.5%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    li {
      list-style: none;
      position: relative;
      padding: 0.7em 0;
    }

    .nav-menu {
      padding: 0;
      display: flex;
      align-items: center;
      gap: 3rem;

      .nav-linkContainer {
        display: flex;
        align-items: center;
        gap: 3rem;


      }

      .nav-divider {
        background-color: $mainFontColor;
        height: 2.5rem;
        width: 1px;
      }

      img {
        height: 4rem;
        width: auto;
        z-index: 5;
      }

      a {
        font-family: "Montserrat-Medium", sans-serif;
        font-size: 1.6rem;
        color: $mainFontColor;
        text-decoration: none;
        z-index: 1;

      }

      .nav-link,
      .nav-link::after,
      .nav-link::before {
        transition: 0.3s;
      }

      .nav-link {
        z-index: 0;
      }

      .nav-link::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: -10%;
        height: 100%;
        border-radius: 3px;
        width: 120%;
        background-color: $gray1Color;
        transform: scaleX(0);
        transform-origin: right;
        transition: transform 0.3s;
        z-index: -1;
        //transition: 0.3s;
      }

      .nav-link:hover::after {
        transform: scaleX(1);
        transform-origin: left;
      }

      .nav-link:hover {
        color: black;
      }

    }

    // button
    .nav-langSetting {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      button {
        color: $mainFontColor;
        border: none;
        background-color: transparent;
        font-size: 1.6rem;
        font-family: "Montserrat-Medium", sans-serif;
        cursor: pointer;
      }

      img {
        padding: 0.5em;
      }

    }
  }

  .nav-background__mobile {
    position: fixed;
    background-color: $white2Color;
    top: 0;
    left: 0;
    width: 100%;
    height: 70px;
    z-index: 2;
    display: none;
  }



  // hamburger

  .expandAnimation {
    width: 100% !important;

  }
  .nav-hamburger {
    display: none;
    justify-content: space-around;
    align-items: flex-end;
    flex-direction: column;
    height: 5rem;
    width: 6rem;
    cursor: pointer;
    padding: 10px;
    z-index: 1;

    .line {
      background-color: $mainFontColor;
      height: 2px;
      transition: width 0.5s ease-in-out;
    }
    .line1 {
      width: 100%;
    }
    .line2 {
      width: 65%;
    }
    .line3 {
      width: 40%;
    }
  }

}

// media

@media (max-width: 900px) {

  nav {
    display: flex;
    width: 100%;

    .mobileLang {
      display: flex;
      //padding: 1em 4em;

      button {
        display: none;
      }
    }

    .nav-Container {

      .nav-background__mobile {
        display: block;
        z-index: 0;
      }


      img {
        .nav-logo {
          position: fixed;
          z-index: 3;
          padding: 0;
          margin: 0;
        }
      }



      .nav-divider {
        display: none;
      }

      // mobile nav
      .nav-linkContainer {
        position: fixed;
        top: 70px;
        right: 0;
        width: 100%;
        height: 100vh;
        background-color: $white2Color;
        flex-direction: column;
        padding-top: 5em;
        transform: translateY(-100%);
        transition: transform 0.5s ease-in-out;
        align-items: center;
        overflow: hidden;
        z-index: -1;
        //border-top: 1px solid black;

        li:first-child {
          //margin-top: 1em;
        }

        li:last-child {
          margin-bottom: 70px;
        }

        li {


          a {
            z-index: 0;
            font-size: 2rem;
            //padding: 1em 4em;
            //font-weight: bold;
          }
        }


      }

      .nav-mobileActive {

        overflow: scroll;
        transform: translateY(0);
      }

      .nav-langSetting {
        display: none;

        button {
          display: none;
        }
        img {
          display: none;
        }
      }

      .nav-hamburger {
        display: flex;
        z-index: 5;
      }
    }
  }

}

@media (max-width: 476px) {
  nav {

    .nav-Container {
      margin-left: 5%;
      margin-right: 5%;
    }
  }
}

@media (min-width: 477px) and (max-width: 1919px){

  nav {

    .nav-Container {
      margin-left: 7%;
      margin-right: 7%;
    }
  }
}
