body {
  background-color: #f0f0f0;
}

img {
  width: 100%;
}

@media (max-width: 476px) {
  .imgHead {
    display: none;
  }
}
@media (max-width: 1200px) and (min-width: 477px) and (orientation: portrait) {
  .imgHead {
    display: none;
  }
}
@media (max-width: 1200px) and (min-width: 477px) {
  .imgHead {
    height: auto;
    width: 100%;
  }
}

